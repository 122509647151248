<template>
    <div>
        <div v-sortable="{ handle: '.handle', onUpdate }">
            <div v-for="(bump, index) in items" v-bind:key="'bump-' + index" class="product-card card br p-t-3 p-b-3 m-b-4" v-bind:data-encoded-id="bump.encoded_id">
                <div class="card-content row row-middle row-compress">
                    <div v-if="items.length > 1" class="slat-controls slat-controls--mini m-vertical-none m-l-2">
                        <span class="i-container i-filled-dark">
                            <span class="handle">
                                <!-- /icons/drag.svg -->
                                <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                            </span>
                        </span>
                    </div>
                    <div class="slat-content column">
                        <h4 class="card-title c-primary f-s-4 m-b-0">{{ bump.headline }}</h4>
                        <p class="c-medium-tint f-s-3">{{ getMembership(bump.membership_id).name }} • {{ formattedPrice(bump.pricing_plan) }}</p>
                    </div>
                    <div class="slat-aside text-right m-r-4">
                        <a class="btn btn-text btn-text--accent open-modal" data-modal="modal-bump" v-on:click="editBump(index)">{{ trans('Edit Bump') }}</a>
                    </div>
                </div>
                <input type="hidden" v-bind:name="'bumps[' + index + '][encoded_id]'" v-bind:value="bump.encoded_id" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][membership_id]'" v-bind:value="bump.membership_id" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][pre_headline]'" v-bind:value="bump.pre_headline" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][headline]'" v-bind:value="bump.headline" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][description]'" v-bind:value="bump.description" />
                <input v-for="(feature, featureIndex) in removeEmptyFeatures(bump.features)" type="hidden" v-bind:name="'bumps[' + index + '][features][' + featureIndex + '][icon]'" v-bind:value="feature.icon" v-bind:key="'feature-icon-' + featureIndex" />
                <input v-for="(feature, featureIndex) in removeEmptyFeatures(bump.features)" type="hidden" v-bind:name="'bumps[' + index + '][features][' + featureIndex + '][text]'" v-bind:value="feature.text" v-bind:key="'feature-text-' + featureIndex" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][image]'" v-bind:value="bump.image" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][button_label]'" v-bind:value="bump.button_label" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][pricing_plan][name]'" v-bind:value="bump.pricing_plan.name" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][pricing_plan][type]'" v-bind:value="bump.pricing_plan.type" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][pricing_plan][price]'" v-bind:value="bump.pricing_plan.price" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][pricing_plan][interval]'" v-bind:value="bump.pricing_plan.interval" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][pricing_plan][currency]'" v-bind:value="bump.pricing_plan.currency" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][pricing_plan][interval_count]'" v-bind:value="bump.pricing_plan.interval_count" />
                <input type="hidden" v-bind:name="'bumps[' + index + '][original_price]'" v-bind:value="bump.original_price" />
            </div>
        </div>
        <div class="text-center p-t-3">
            <button v-on:click="newBump" type="button" class="btn btn-text btn-text--accent btn-icon m-b-4">
                <span class="icon i-container i-filled-accent">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon i-size-small inline"><g class="icon"><path d="M11 5v14h2V5z"></path><path d="M19 11H5v2h14z"></path></g></svg>
                </span>
                {{  trans('Add Order Bump') }}
            </button>
        </div>
        <bump-modal
            v-if="modalShow"
            ref="modal-bump"
            v-bind:key="modalKey"
            v-bind:parent="parentMembership"
            v-bind:memberships="memberships"
            v-bind:allowed-types="allowedTypes"
            v-on:close="closeModal"
            v-on:save="saveBump"
            v-on:delete="deleteBump"
        ></bump-modal>

        <input type="hidden" v-bind:name="'for_removal[' + index + ']'" v-for="(id, index) in forRemoval" v-bind:value="id" v-bind:key="'remove-' + id" />
    </div>
</template>
<script>
import { PLAN_TYPE_ONE_TIME, PLAN_TYPE_SUBSCRIPTION } from '../../constants.js';
import { EventBus } from '../../event-bus.js';
import Orderable from '../../mixins/Orderable.js';
import TranslateInterval from '../../mixins/TranslateInterval.js';
import BumpModal from './BumpModal.vue';

export default {
    mixins: [
        Orderable,
        TranslateInterval,
    ],
    components: {
        BumpModal
    },
    props: {
        bumps: {
            type: Array,
            required: true
        },
        memberships: {
            type: Array,
            required: true,
        },
        allowedBumpTypes: {
            type: Array,
            required: true
        },
        parentMembership: {
            type: Object,
            required: true
        },
    },
    watch: {
        items: {
            handler: function (bumps) {
                this.allowedTypes = this.filterAllowedTypes(bumps);
            },
            deep: true
        }
    },
    data() {
        return {
            modal: null,
            items: this.bumps.map(bump => { // This is done to convert price from integer to float
                return {
                    encoded_id: bump.encoded_id,
                    membership_id: bump.membership_id,
                    pre_headline: bump.pre_headline,
                    headline: bump.headline,
                    description: bump.description,
                    features: bump.features || [],
                    image: bump.image,
                    image_url: bump.image ? bump.image_url.full : null,
                    button_label: bump.button_label,
                    pricing_plan: {
                        name: bump.pricing_plan.name,
                        price: bump.pricing_plan.price / 100,
                        interval: bump.pricing_plan.interval,
                        type: bump.pricing_plan.type,
                        currency: bump.pricing_plan.currency,
                        interval_count: bump.pricing_plan.interval_count,
                    },
                    original_price: bump.original_price ? bump.original_price / 100 : null,
                };
            }),
            allowedTypes: this.filterAllowedTypes(this.bumps),
            numberFormatter: new Intl.NumberFormat(window.Kourses.locale, {style: 'currency', currency: window.Kourses.currency}),
            modalKey: 1,
            modalShow: false,
            forRemoval: [],
            model: 'Kourses\\Models\\MembershipBump',
        }
    },
    methods: {
        newBump() {
            this.modalShow = true;

            this.$nextTick(() => {
                this.$refs['modal-bump'].show = true;
            });
        },
        editBump(index) {
            this.modalShow = true;

            this.$nextTick(() => {
                this.$refs['modal-bump'].show = true;
                this.$refs['modal-bump'].index = index;
                this.$refs['modal-bump'].loadBump(this.items[index]);
            });
        },
        saveBump(bump, index) {
            if (index !== null) {
                this.items[index] = bump;
            } else {
                this.items.push(bump);
            }

            this.closeModal();

            EventBus.$emit('form:check');
        },
        deleteBump(index) {
            if (this.items[index].encoded_id) {
                this.forRemoval.push(this.items[index].encoded_id);
            }

            this.items.splice(index, 1);

            this.closeModal();

            EventBus.$emit('form:check');
        },
        formattedPrice(plan) {
            const formattedPrice = this.numberFormatter.format(plan.price);

            if (plan.type === PLAN_TYPE_ONE_TIME) {
                return formattedPrice;
            }

            return this.trans(':price per :interval', {'price': formattedPrice, 'interval': this.translateInterval(plan.interval)});
        },
        closeModal() {
            this.modalShow = false;
            this.modalKey++;
        },
        filterAllowedTypes(bumps) {
            const hasSubscriptionBump = bumps.some(bump => bump.pricing_plan.type === PLAN_TYPE_SUBSCRIPTION);

            if (hasSubscriptionBump) {
                return [PLAN_TYPE_ONE_TIME];
            }

            return this.allowedBumpTypes;
        },
        removeEmptyFeatures(features) {
            return features.filter(feature => feature.text !== '');
        },
        getMembership(membershipId) {
            return this.memberships.find(membership => membership.id === membershipId);
        },
    },
}
</script>