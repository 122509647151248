<template>
    <div v-on:click="toggleMenu" v-on-clickaway="closeMenu" class="dropdown-container btn width-auto-min btn-icon--right btn-text header-nav--link p-l-0 p-r-2 i-container m-nav-logo">
        <span class="f-s-3 f-w-bold c-primary xs-none m-l-2 m-nav-logo--text">{{ websiteName }}</span>
        <span class="i-container i-filled-dark">
            <!-- /icons/caret-up-down.svg -->
            <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path style="fill:none;fill-rule:nonzero" d="M0 0h24v24H0z"/><path d="m17 14-5-6-5 6h10Z" style="fill-rule:nonzero" transform="translate(0 -3)"/><path style="fill:none" d="M0 0h24v24H0z" transform="translate(0 3)"/><path d="m7 13 5 6 5-6H7Z"/></svg>
        </span>
        <transition name="show">
            <div v-if="show" class="dropdown dropdown--width-default dropdown--bd dropdown-p--left block">
                <div v-if="websites && websites.length > 0" class="dropdown-group text-left">
                    <a v-for="website in websites" v-bind:key="'website-' + website.encoded_id" class="c-primary p-t-2 p-b-2 p-l-4 p-r-4 f-s-4 block h-anim h-bg-accent--light" v-bind:href="'/websites/switch/' +  website.encoded_id">{{ website.name }}</a>
                </div>
                <div  v-if="websites && websites.length === 0" class="dropdown-group text-left">
                    <p class="c-muted f-s-2 p-2 m-b-0">You only have one website. <a href="/websites/create">Create another one</a>.</p>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway';

export default {
    directives: {
        onClickaway: onClickaway
    },
    props: {
        closeOnClick: {
            type: Boolean,
            default: true,
        },
        websiteName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            show: false,
            websites: null,
        }
    },
    watch: {
        show: function (value) {
            if (value === true && this.websites === null) {
                this.fetchWebsites();
            }
        }
    },
    methods: {
        openMenu() {
            this.show = true;
        },
        toggleMenu() {
            if (this.closeOnClick === false && this.show === true) {
                return;
            }

            this.show = ! this.show;
        },
        closeMenu() {
            this.show = false;
        },
        fetchWebsites() {
            window.axios.get(window.Kourses.apiBaseUrl + '/websites')
                .then(response => {
                    this.websites = response.data.websites;
                })
                .catch(error => {
                    console.error(error);
                });
        },
    },
    created() {
        window.addEventListener('keyup', event => {
            if (this.show && event.keyCode === 27) {
                this.closeMenu();
            }
        });
    },
}
</script>