<template>
    <div>
        <p v-if="showTitle" class="f-s-5 f-w-bold m-t-5 text-center">{{ trans('Give imported members access to memberships') }}</p>
        <div v-for="(membership, index) in selectedMemberships" v-bind:key="membership.id" class="card br row m-b-6">
            <div class="column">
                <div class="card-content row row-collapse-indent row-middle m-t-4 m-b-4">
                    <div class="slat-content column">
                        <h4 class="card-title c-primary f-s-4 m-b-0">{{ membership.name }}</h4>
                        <div class="card-type f-s-2 c-muted m-t-1">{{ membershipHubs(membership) }}</div>
                    </div>
                    <div class="slat-aside text-right m-r-4">
                        <a v-on:click.prevent="deleteItem(index)" class="i-container i-filled-dark btn btn-text width-auto-min p-0 m-r-0" href="#remove-membership">
                            <!-- /icons/remove.svg -->
                            <svg class="icon i-size-medium" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"/><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"/></g></svg>
                        </a>
                    </div>
                </div>
            </div>
            <input type="hidden" v-bind:name="'memberships[' + index + ']'" v-model="membership.id">
            <div class="columns p-b-1">
                <div class="br-top p-t-1"></div>
                <div class="row row-collapse row-middle">
                    <div class="column large-5">
                        <!-- TODO: this should only be shown to memberships with a hub that has a course feature -->
                        <div class="control-group text-left p-b-micro p-t-micro">
                            <div class="form-group-addon inline">
                                <label class="control control-checkbox block c-muted m-b-0">
                                    {{ trans('Skip original drip schedule') }}
                                    <input type="checkbox" v-bind:name="'skip_drip_schedule[' + membership.id + ']'" value="1">
                                    <div class="control-indicator control-checkbox-indicator"></div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="c-muted f-s-2 p-r-4 text-right">{{ trans('Set Membership Expiry') }}</div>
                    </div>
                    <div class="column">
                        <div class="form-group m-b-0">
                            <date-pick
                                v-model="membership.ends_at"
                                v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick a date'), 'name': 'ends_at[' + membership.id + ']'}"
                                v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                                v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                                v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                                v-bind:weekdays="datePickLabels.weekdays"
                                v-bind:months="datePickLabels.months"
                            ></date-pick>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selectedMemberships.length > 0" class="m-t-4 m-b-6">
            <div class="control-group text-center p-b-micro p-t-micro">
                <div class="form-group-addon inline">
                    <label class="control control-checkbox block c-muted m-b-0">
                        {{ trans('Send notification email') }}
                        <input type="checkbox" name="send_notification" value="1" v-model="shouldSendNotification">
                        <div class="control-indicator control-checkbox-indicator"></div>
                    </label>
                </div>
                <div v-if="showRunEmailIntegrationsCheckbox" class="form-group-addon inline m-l-3">
                    <label class="control control-checkbox block c-muted m-b-0">
                        {{ trans('Run Email Marketing Integrations') }}
                        <input type="checkbox" name="run_email_integrations" value="1" v-model="shouldRunEmailIntegrations">
                        <div class="control-indicator control-checkbox-indicator"></div>
                    </label>
                </div>
            </div>
        </div>
        <div class="text-center">
            <button v-on:click="addMembership" type="button" class="btn btn-text btn-text--accent btn-icon m-b-4">
                <span class="icon i-container i-filled-accent">
                    <!-- /icons/add.svg -->
                    <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M11 5v14h2V5z"/><path d="M19 11H5v2h14z"/></g></svg>
                </span>
                {{ trans('Add Membership') }}
            </button>
        </div>
        <div v-if="errors.length > 0" class="form-help c-error">
            {{ errors }}
        </div>

        <membership-picker-modal
            v-bind:memberships="filteredMemberships"
            v-on:close="modal.show = false"
            v-on:select-membership="selectMembership"
            ref="membership-picker-modal"
        ></membership-picker-modal>
    </div>
</template>
<script>
import DatePick from 'vue-date-pick';
import DatePickLocalizationMixin from '../fields/DatePickLocalizationMixin.vue';
import MembershipPickerModal from '../memberships/MembershipPickerModal.vue';
import { EventBus } from '../../event-bus';
import HasMembershipHubs from '../../mixins/HasMembershipHubs';

export default {
    components: {
        DatePick,
        MembershipPickerModal,
    },
    mixins: [
        DatePickLocalizationMixin,
        HasMembershipHubs,
    ],
    props: {
        memberships: {
            type: Array,
        },
        selected: {
            type: Array,
        },
        errors: {
            type: String,
            default: '',
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
        sendNotification: {
            type: Boolean,
            default: true,
        },
        runEmailIntegrations: {
            type: Boolean,
            default: false,
        },
        showRunEmailIntegrationsCheckbox: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedMemberships: [],
            selectedMembershipIds: _.get(this, 'selected', []),
            modal: null,
            shouldSendNotification: this.sendNotification,
            shouldRunEmailIntegrations: this.runEmailIntegrations,
        }
    },
    computed: {
        filteredMemberships() {
            return this.memberships.filter((membership) => {
                return _.indexOf(this.selectedMembershipIds, membership.id) < 0 && _.indexOf(this.selectedMembershipIds, membership.id + '') < 0;
            });
        },
    },
    methods: {
        addMembership() {
            this.modal.show = true;
        },
        selectMembership(membership) {
            this.modal.show = false;
            this.selectedMemberships.push(membership);
            this.selectedMembershipIds.push(membership.id);
            EventBus.$emit('form:element:changed', 'memberships', this.selectedMembershipIds.join('-'));
        },
        deleteItem(index) {
            this.selectedMemberships.splice(index, 1);
            this.selectedMembershipIds.splice(index, 1);
            EventBus.$emit('form:element:changed', 'memberships', this.selectedMembershipIds.join('-'));
        },
    },
    mounted() {
        this.modal = this.$refs['membership-picker-modal'];

        this.$nextTick(() => {
            EventBus.$emit('form:element:initialize', 'memberships', this.selectedMembershipIds.join('-'));
        });
    },
    created() {
        if (this.selectedMembershipIds.length && this.memberships.length) {
            this.selectedMemberships = this.selectedMembershipIds.map(id => _.find(this.memberships, {'id': id}) || _.find(this.memberships, {'id': parseInt(id)}));
        }
    }
}
</script>