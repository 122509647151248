<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <nav class="nav-tab">
                <a v-bind:class="{'nav-item--active': selectedTab === 'email'}" class="nav-item f-s-4 m-l-3 m-r-3 p-t-3 p-b-3" href="#select" v-on:click.prevent="selectedTab = 'email'">{{ trans('Email Integrations') }}</a>
                <a v-bind:class="{'nav-item--active': selectedTab === 'cart'}" class="nav-item f-s-4 m-l-3 m-r-3 p-t-3 p-b-3" href="#select" v-on:click.prevent="selectedTab = 'cart'">{{ trans('Cart Integrations') }}</a>
                <a v-if="dataOtherIntegrations.length > 0" v-bind:class="{'nav-item--active': selectedTab === 'other'}" class="nav-item f-s-4 m-l-3 m-r-3 p-t-3 p-b-3" href="#select" v-on:click.prevent="selectedTab = 'other'">{{ trans('Other Integrations') }}</a>
            </nav>
        </div>

        <div v-if="selectedTab !== 'email'" v-for="(integration, index) in availableIntegrations" v-bind:class="{'h-bg-accent--light' : selectedIntegration !== integration.provider}" v-bind:key="index" class="slat h-anim h-anim-select m-b-0">
            <div class="row row-compress-indent row-compress p-3 row-middle">
                <div class="slat-figure--medium column small-12">
                    <img v-bind:src="integration.icon" class="br br-c--light integration-icon">
                </div>
                <div class="slat-content column">
                    <h2 class="card-title c-primary f-s-4 m-b-1">{{ integration.title }}</h2>
                    <p class="f-s-3 c-muted m-b-0">{{ integration.description }}</p>
                </div>

                <div class="slat-aside text-right m-r-4 m-t-2">
                    <button v-if=" ! isAlreadyIntegrated(integration)" type="button" v-on:click="toggleIntegration(integration)" class="btn btn-text btn-text--accent h-anim-select-item modal-close-external">
                        {{ trans('Choose service') }}
                    </button>
                    <button v-else type="button" v-on:click="selectedTab === 'cart' ? disconnectCartIntegration(integration) : disconnectOtherIntegration(integration)" class="btn btn-secondary">{{ trans('Disconnect') }}</button>
                </div>
            </div>

            <div v-if="selectedIntegration === integration.provider" class="row m-b-5">
                <div class="small-12 columns">
                    <div v-if="integration.type === 'webhook'" class="form-group">
                        <label>
                            {{ trans('Webhook URL') }}
                            <clipboard v-bind:data="integration.webhook" inline-template>
                                <div class="form-group-addon copy-append">
                                    <input class="input__field" type="text" readonly v-bind:value="integration.webhook">
                                    <a class="btn btn-text form-append c-accent text-right btn-copy c-bright-bg br-no-radius" v-clipboard="copyData" v-on:success="handleSuccess">{{ trans('Copy Url') }}</a>
                                </div>
                            </clipboard>
                        </label>
                    </div>

                    <div v-for="(field, key) in integration.fields" v-bind:key="key">
                        <div class="form-group">
                            <label>{{ field.label }}</label>
                            <input type="text" class="input__field" v-model="integration.fields[key].value">
                        </div>
                        <div class="form-help">{{ field.help }}</div>
                    </div>

                    <div class="text-right">
                        <button v-on:click="connectIntegration(integration, selectedTab)" v-bind:class="{'btn-loading': loading}" class="btn btn-primary">
                            {{ trans('Save and connect') }}
                            <div v-if="loading" class="loader">
                                <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                    <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="selectedTab === 'email'" v-for="(integration, index) in availableIntegrations" v-bind:class="{'h-bg-accent--light' : selectedIntegration !== integration.provider}" v-bind:key="index" class="slat h-anim h-anim-select m-b-0">
            <div class="row row-compress-indent row-compress p-3 row-middle">
                <div class="slat-figure--medium column small-12">
                    <img class="br br-c--light integration-icon" v-bind:src="integration.icon">
                </div>
                <div class="slat-content column">
                    <h2 class="card-title c-primary f-s-4 m-b-1">{{ integration.title }}</h2>
                    <p class="f-s-3 c-muted m-b-0">{{ integration.description }}</p>
                </div>

                <div v-if="integration.type == 'oauth'" class="slat-aside text-right m-r-4 m-t-2">
                    <button v-if="integration.authorized && ! isAlreadyIntegrated(integration)" v-on:click="connectOAuthIntegration(integration)" type="button" class="btn btn-text btn-text--accent h-anim-select-item modal-close-external">
                        {{ trans('Choose service') }}
                    </button>
                    <button v-else-if="integration.authorized" v-on:click="disconnectEmailIntegration(integration)" type="button" class="btn btn-secondary">{{ trans('Disconnect') }}</button>
                    <a v-else v-bind:href="integration.connect_url" class="btn btn-text btn-text--accent h-anim-select-item modal-close-external" data-toggle="service">
                        {{ trans('Connect') }}
                    </a>
                </div>

                <div v-else class="slat-aside text-right m-r-4 m-t-2">
                    <button v-if=" ! isAlreadyIntegrated(integration)" v-on:click="selectIntegration(integration)" type="button" class="btn btn-text btn-text--accent h-anim-select-item modal-close-external">
                        {{ trans('Choose service') }}
                    </button>
                    <button v-else type="button" v-on:click="disconnectEmailIntegration(integration)" class="btn btn-secondary">{{ trans('Disconnect') }}</button>
                </div>
            </div>

            <div v-if="integration.type == 'default' && selectedIntegration == integration.provider" class="row m-b-5">
                <div class="small-12 columns">
                    <template v-for="(field, fieldKey) in integration.config.connection.fields">
                        <div v-bind:class="{'has-error': errors.has(fieldKey)}" v-bind:key="'label-' + fieldKey" class="form-group">
                            <label>{{ field.label }}</label>
                            <input type="text" value="" class="input__field" v-model="integration.connection[fieldKey]">
                        </div>
                        <div v-if="errors.has(fieldKey)" v-bind:key="'error' + fieldKey" class="form-help">{{ errors.get(fieldKey) }}</div>
                    </template>
                    <div v-if="errors.has(integration.provider)" class="form-help c-error">{{ errors.get(integration.provider) }}</div>
                    <div class="text-right">
                        <button v-on:click="connectEmailIntegration(integration)" v-bind:class="{'btn-loading': saving}" class="btn btn-primary">
                            {{ trans('Save and connect') }}
                            <div v-if="saving" class="loader">
                                <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                    <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div slot="footer"></div>
    </modal>
</template>
<script>
import Modal from '../modals/Modal.vue';
import { EventBus } from '../../event-bus.js';
import Errors from '../../errors.js';
import { integrationEmailConnectUrl } from '../../routes.js';

export default {
    components: {
        Modal
    },
    props: {
        cartIntegrations: {
            type: [Array, Object]
        },
        emailIntegrations: {
            type: [Array, Object]
        },
        otherIntegrations: {
            type: [Array, Object]
        },
        activeCartIntegrations: {
            type: [Array, Object]
        },
        activeEmailIntegrations: {
            type: [Array, Object]
        },
        activeOtherIntegrations: {
            type: [Array, Object]
        },
        tab: {
            type: String,
            default: 'email'
        }
    },
    data() {
        return {
            show: false,
            selectedIntegration: null,
            saving: false,
            loading: false,
            selectedTab: this.tab,
            errors: new Errors(),
            selectedEmailIntegrations: _.get(this, 'activeEmailIntegrations', []),
            selectedCartIntegrations: _.get(this, 'activeCartIntegrations', []),
            selectedOtherIntegrations: _.get(this, 'activeOtherIntegrations', []),
            dataEmailIntegrations: _.get(this, 'emailIntegrations', []),
            dataCartIntegrations: _.get(this, 'cartIntegrations', []),
            dataOtherIntegrations: _.get(this, 'otherIntegrations', []),
        }
    },
    computed: {
        availableIntegrations() {
            switch(this.selectedTab) {
                case 'other':
                    return this.dataOtherIntegrations;
                case 'cart':
                    return this.dataCartIntegrations;
                case 'email':
                default:
                    return this.dataEmailIntegrations;
            }
        },
        activeIntegrations() {
            switch(this.selectedTab) {
                case 'other':
                    return this.selectedOtherIntegrations;
                case 'cart':
                    return this.selectedCartIntegrations;
                case 'email':
                default:
                    return this.selectedEmailIntegrations;
            }
        }
    },
    methods: {
        isAlreadyIntegrated(integration) {
            return _.findIndex(this.activeIntegrations, {'provider': integration.provider}) > -1;
        },
        selectIntegration(integration) {
            this.errors.clear();
            this.selectedIntegration = integration.provider;
        },
        connectEmailIntegration(integration) {
            this.saving = true;

            if (this.validateIntegration(integration)) {
                let data = {};

                for (var key in integration.connection) {
                    data[key] = integration.connection[key];
                }

                window.axios.put(integrationEmailConnectUrl(integration), {
                    'data': data
                }).then((response) => {
                    if (response.data.authorized === true) {
                        this.selectedEmailIntegrations.push(integration);
                        EventBus.$emit('email:integrations:update', response.data);
                        this.close();
                    } else {
                        this.saving = false;
                        this.errors.set(integration.provider, this.trans('Connection not authorized. Check your settings and try again.'));
                    }
                }).catch(({response}) => {
                    console.log(response);
                    this.saving = false;
                });
            } else {
                this.saving = false;
            }
        },
        disconnectEmailIntegration(integration) {
            this.$dialog.confirm(this.trans("Disconnecting this integration will also remove any connected membership actions, such as adding members to email lists and applying tags."), {
                loader: true,
                headline: this.trans('Are you sure you want to disconnect?'),
            }).then((dialog) => {
                window.axios.delete(Kourses.apiBaseUrl + '/integrations/emails/' + integration.uid).then(() => {
                    EventBus.$emit('email:integrations:disconnected', integration);
                    dialog.close();
                });
            });
        },
        disconnectCartIntegration(integration) {
            this.$dialog.confirm(this.trans("If you disconnect it, permissions wouldn't be automatically added on purchase."), {loader: true})
                .then((dialog) => {
                    window.axios.delete(Kourses.apiBaseUrl + '/integrations/carts/' + integration.provider).then(() => {
                        EventBus.$emit('cart:integrations:disconnected', integration);
                        dialog.close();
                    });
                });
        },
        disconnectOtherIntegration(integration) {
            this.$dialog.confirm(this.trans("If you disconnect it, you won't be able to use it accross the website."), {loader: true})
                .then((dialog) => {
                    window.axios.delete(Kourses.apiBaseUrl + '/integrations/other/' + integration.provider).then(() => {
                        EventBus.$emit('other:integrations:disconnected', integration);
                        dialog.close();
                    });
                });
        },
        connectOAuthIntegration(integration) {
            EventBus.$emit('connectOAuthIntegration', integration);
        },
        close() {
            this.loading = false;
            this.show = false;
            this.reset();
        },
        reset() {
            this.selectedIntegration = '';
            this.saving = false;
        },
        validateIntegration(integration) {
            this.errors.clear();

            let valid = true;

            for (var key in integration.config.connection.fields) {
                if ( ! _.has(integration.connection, key) || integration.connection[key] === '') {
                    valid = false;
                    this.errors.set(key, this.trans(':field field is required.', {'field': integration.config.connection.fields[key].label}));
                }
            }

            return valid;
        },
        toggleIntegration(integration) {
            if (this.selectedIntegration === integration.provider) {
                this.selectedIntegration = null;
            } else {
                this.selectedIntegration = integration.provider;
            }
        },
        connectIntegration(integration, type) {
            this.loading = true;

            let data = {};

            for (var key in integration.fields) {
                data[key] = integration.fields[key].value;
            }

            let baseUrl = Kourses.apiBaseUrl + '/integrations/carts/';
            let eventName = 'cart:integrations:update';

            if (type === 'other') {
                baseUrl = Kourses.apiBaseUrl + '/integrations/other/';
                eventName = 'other:integrations:update';
            }

            window.axios.put(baseUrl + integration.provider, {
                'data': data,
            }).then((response) => {
                this.show = false;
                this.loading = false;
                this.selectedIntegration = null;

                if (type === 'other') {
                    this.selectedOtherIntegrations.push(integration);
                } else {
                    this.selectedCartIntegrations.push(integration);
                }

                EventBus.$emit(eventName, integration);
            }).catch(({response}) => {
                console.log(response);
            });
        }
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:new-integration', () => {
            $vm.show = true;
        });

        EventBus.$on('integration:disconnected', (integration, type) => {
            var index = -1;
            switch (type) {
                case 'cart':
                    index = _.findIndex(this.selectedCartIntegrations, {'provider': integration.provider});
                    if (index > -1) {
                        this.selectedCartIntegrations.splice(index, 1);
                    }
                    break;
                case 'other':
                    index = _.findIndex(this.selectedOtherIntegrations, {'provider': integration.provider});
                    if (index > -1) {
                        this.selectedOtherIntegrations.splice(index, 1);
                    }
                    break;
                case 'email':
                default:
                    index = _.findIndex(this.selectedEmailIntegrations, {'provider': integration.provider});
                    if (index > -1) {
                        this.selectedEmailIntegrations.splice(index, 1);
                    }

                    // Update integration data in order for it to be in
                    // unauthorized state after disconnecting it (KS-142)
                    const allIndex = _.findIndex(this.dataEmailIntegrations, {'provider': integration.provider});
                    if (allIndex > -1) {
                        this.$set(this.dataEmailIntegrations[allIndex], 'authorized', false);
                    }

                    break;
            }
        });
    },
}
</script>