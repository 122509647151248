<template>
    <div>
        <div class="m-l-6 column m-r-4">
            <div v-bind:class="{'has-error': validationError, 'is-successful': successfull}" class="form-group">
                <input class="input__field" type="text" v-bind:name="name" v-model="domain">
            </div>

            <div v-if="validationError" class="form-help">{{ validationError }}</div>
            <div v-if="successfull" class="form-help">{{ trans('Domain verified successfully.') }}</div>

            <div v-if="showInstructions" class="alert p-3 c-muted br br-c--fade alert c-warning-bg">
                <div class="m-b-2">
                    <div class="f-s-1 small-2 m-b-1">{{ trans('Type') }}</div>
                    <div class="f-w-bold f-s-1">CNAME</div>
                </div>
                <div class="m-b-2">
                    <div class="f-s-1 small-2 m-b-1">{{ trans('Name') }}</div>
                    <div class="f-w-bold f-s-1">{{ domainWithoutHttps }}</div>
                </div>
                <div>
                    <div class="f-s-1 small-2 m-b-1">{{ trans('Value') }}</div>
                    <div class="f-w-bold f-s-1" style="flex: 0 0;">a370f74205aa142c5853f7190315645e-c80aa80f98dd272d.elb.us-east-1.amazonaws.com</div>
                </div>
            </div>
        </div>
        <button v-on:click="verifyDomain" type="button" class="btn btn-outline btn-outline--bright">
            <span v-if=" ! loading">{{ trans('Verify') }}</span>
            <div v-else class="loader">
                <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                    <svg class="i-stroke-accent" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                </div>
            </div>
        </button>
    </div>
</template>
<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: false
        },
        error: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            domain: this.value,
            loading: false,
            successfull: false,
            validationError: this.error,
            showInstructions: false,
        }
    },
    computed: {
        domainWithoutHttps() {
            return this.domain.replace(/^https?:\/\//, '');
        }
    },
    methods: {
        verifyDomain() {
            this.successfull = false;
            this.validationError = this.error;
            this.showInstructions = false;

            if ( ! this.domain) {
                this.validationError = this.trans('Please enter a domain');

                return;
            }

            this.loading = true;

            window.axios.post(Kourses.apiBaseUrl + '/website/domain/verify', {
                domain: this.domain
            }).then(() => {
                this.loading = false;
                this.successfull = true;
            }).catch((error) => {
                this.loading = false;

                if (error.response && error.response.data && error.response.data.message) {
                    this.validationError = error.response.data.message;
                }

                if (this.validationError === this.trans('This domain is not correctly pointed to Kourses. Please ensure you add a DNS record matching the information below:')) {
                    this.showInstructions = true;
                }
            });
        }
    }
}
</script>