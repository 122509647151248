<template>
    <div>
        <div v-if="type === '1'" class="row row-compress row-compress-indent p-b-4">
            <div class="column" v-bind:class="columnWidthClass" v-for="(item, index) in items" v-bind:key="index">
                <a class="card card-mini p-4 inline" v-bind:class="{'card-item-active': index === active}" v-bind:href="'#' + index" v-on:click.prevent="selectItem(index)">
                    <div class="card-item card-link">
                        <div class="card-figure m-t-2">
                            <img v-bind:src="item.image"/>
                        </div>
                        <div class="card-content p-t-2 p-b-2 text-center">
                            <div class="card-subtitle f-w-medium c-primary">{{ item.title }}</div>
                            <small class="c-muted f-s-2" v-if="item.description">{{ item.description }}</small>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div v-if="type === '2'" class="row row-expand">
            <div v-for="(item, index) in items" class="column" v-bind:key="index">
                <div v-if="item.type !== 'dummy'" class="card card-mini br rounded-ittybitty" v-bind:class="{'card-item-active': index === active}">
                    <a class="card-item card-link" v-bind:href="'#' + index" v-on:click.prevent="selectItem(index)">
                        <div class="cover-container uploads uploads-r-16_9">
                            <div class="cover row row-middle upload-content">
                                <div class="cover-image cover-image-overlay">
                                    <div class="cover-overlay"></div>
                                    <img class="rounded-ittybitty" v-bind:src="item.image" width="348" height="195">
                                </div>
                                <div class="cover-content column text-center card-status">
                                    <div class="i-container dropshadow-medium inline rounded-xlarge c-accent-bg i-filled-bright">
                                        <!-- /icons/bullet-check-3.svg -->
                                        <svg class="icon i-size-medium" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5.707 12.293a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.414 0l10-10a1 1 0 1 0-1.414-1.414L9 15.586l-3.293-3.293z" class="icon"/></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div v-if="item.type !== 'dummy'" class="card-aside m-t-3">
                    <div class="row row-collapse">
                        <div class="column">
                            <h4 class="c-primary f-s-4 f-w-bold">{{ item.title }}</h4>
                        </div>
                        <div v-if="item.preview && item.preview.length > 0" class="m-l-4 text-right">
                            <a class="f-s-3 inline" v-bind:href="item.preview">{{ trans('Preview') }}</a>
                        </div>
                    </div>
                </div>
                <div v-if="item.type === 'dummy'" class="card card-mini br">
                    <div class="card-item">
                        <div class="cover-container uploads uploads-r-16_9">
                            <div class="cover row row-middle upload-content">
                                <div class="cover-content column text-center">
                                    <span class="c-primary f-s-4 f-w-bold">{{ item.description }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <input type="hidden" v-bind:name="name" v-model="active" />
    </div>
</template>
<script>
    import { EventBus } from '../../event-bus.js';

    export default {
        props: {
            name: {
                type: String,
                required: true
            },
            items: {
                type: [Array, Object]
            },
            selected: {
                type: String
            },
            type: {
                type: String,
                default: '1'
            }
        },
        data() {
            return {
                active: this.selected,
            }
        },
        computed: {
            columnWidthClass() {
                if (Array.isArray(this.items)) {
                    return this.items.length > 1
                        ? 'small-' + 12 / this.items.length
                        : 'small-4';
                }

                return Object.keys(this.items).length > 1
                    ? 'small-' + 12 / Object.keys(this.items).length
                    : 'small-4';
            }
        },
        watch: {
            selected(value) {
                this.active = value;
            },
        },
        methods: {
            selectItem(index) {
                this.active = index;
                this.$parent.$emit('item-selected', index);
                this.$emit('selected', index);
                EventBus.$emit('field:' + this.name + ':selected', index);
                EventBus.$emit('form:element:changed', this.name, index);
            }
        }
    }
</script>