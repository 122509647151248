<template>
    <dropdown-menu
        style="height: 25px;"
        v-bind:show-caret-icon="false">
        <span class="text-right f-s-2" slot="trigger">&bull;&bull;&bull;</span>
        <a v-bind:href="hubPreviewLink()" class="row row-collapse row-middle c-primary p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 h-anim h-bg-accent--light" target="_blank">
            <div class="column">
                <span class="c-primary f-size-small">{{ trans('Preview') }}</span>
            </div>
            <div class="text-right">
                <span class="i-container i-filled-dark">
                    <!-- /icons/out-link-small.svg -->
                    <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M18.125 5H12v1.75h4.025l-5.513 5.513 1.225 1.224 5.513-5.512V12H19V5.875C19 5.35 18.65 5 18.125 5z"/><path d="M17.25 19H5.875C5.35 19 5 18.65 5 18.125V6.75c0-.525.35-.875.875-.875h3.5v1.75H6.75v9.625h9.625v-2.625h1.75v3.5c0 .525-.35.875-.875.875z"/></g></svg>
                </span>
            </div>
        </a>
        <div class="br-top"></div>
        <div>
            <a v-bind:href="hubEditLink()" class="c-primary p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 block h-anim h-bg-accent--light">{{ trans('Edit Hub Settings') }}</a>
        </div>
        <div>
            <button v-on:click="cloneHub" href="#clone" type="button" class="c-primary cu-p btn-block text-left p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 block h-anim h-bg-accent--light">{{ trans('Duplicate Hub') }}</button>
        </div>
        <div v-if="canDeleteHubs" class="br-top"></div>
        <div v-if="canDeleteHubs" >
            <form method="POST" v-bind:action="hubDeleteLink()">
                <input type="hidden" name="_token" v-bind:value="csrfToken" />
                <input type="hidden" name="_method" value="DELETE" />
                <button v-confirm="{'loader': true, 'message': trans('This will delete all hub content including its features. Hubs cannot be recovered once deleted.'), 'anotherMessage': trans('Confirm you would still like to delete this hub?')}" type="submit" class="c-error p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 btn-block btn-block--left h-anim h-bg-accent--light cu-p">{{ trans('Delete Hub') }}</button>
            </form>
        </div>
    </dropdown-menu>
</template>
<script>
import { hubDeleteLink, hubEditLink, hubCloneLink } from '../../routes';
import DropdownMenu from '../DropdownMenu.vue';

export default {
    components: {
        DropdownMenu
    },
    props: {
        hub: {
            type: Object
        },
        canDeleteHubs: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            csrfToken: window.Laravel.csrfToken,
        }
    },
    methods: {
        hubEditLink() {
            return hubEditLink(this.hub);
        },
        hubDeleteLink() {
            return hubDeleteLink(this.hub);
        },
        hubPreviewLink() {
            return window.Kourses.activeBaseUrl + '/goto/website?redirect=hub/' + this.hub.slug;
        },
        cloneHub() {
            this.$dialog.confirm(this.trans('Are you sure you want to clone selected hub and all of its content?'), {loader: true})
                .then((dialog) => {
                    window.axios
                        .post(hubCloneLink(this.hub))
                        .then((response) => {
                            window.location.href = hubEditLink(response.data);
                        })
                        .catch((error) => {
                            dialog.close();
                            this.$toast.danger(error.response.data.message);
                        });
                });
        }
    },
}
</script>