const COMMENT_STATUS_ALL = 'all';
const COMMENT_STATUS_APPROVED = 'approved';
const COMMENT_STATUS_PENDING = 'pending';
const COMMENT_STATUS_DELETED = 'deleted';

const PLAN_TYPE_FREE = 'free';
const PLAN_TYPE_ONE_TIME = 'one-time';
const PLAN_TYPE_INSTALLMENT_PLAN = 'installment-plan';
const PLAN_TYPE_SUBSCRIPTION = 'subscription';

const PLAN_INTERVAL_DAY = 'day';
const PLAN_INTERVAL_WEEK = 'week';
const PLAN_INTERVAL_YEAR = 'year';
const PLAN_INTERVAL_MONTH = 'month';
const PLAN_INTERVAL_QUARTER = 'quarter';
const PLAN_INTERVAL_2_WEEKS = '2-weeks';
const PLAN_INTERVAL_6_MONTHS = '6-months';

const HUB_FEATURE_TYPE_COMMUNITY = 'community';

const MEMBER_ACCOUNT_TYPE_REGULAR = 'regular';

export {
    COMMENT_STATUS_ALL, COMMENT_STATUS_APPROVED, COMMENT_STATUS_DELETED, COMMENT_STATUS_PENDING,
    PLAN_TYPE_FREE, PLAN_TYPE_INSTALLMENT_PLAN, PLAN_TYPE_ONE_TIME, PLAN_TYPE_SUBSCRIPTION,
    PLAN_INTERVAL_DAY, PLAN_INTERVAL_WEEK, PLAN_INTERVAL_YEAR, PLAN_INTERVAL_MONTH, PLAN_INTERVAL_QUARTER, PLAN_INTERVAL_2_WEEKS, PLAN_INTERVAL_6_MONTHS,
    HUB_FEATURE_TYPE_COMMUNITY,
    MEMBER_ACCOUNT_TYPE_REGULAR,
}