<template>
    <div>
        <section v-if="selected.length > 0" class="section p-t-6 p-b-6">
            <div class="panel row">
                <div class="panel-aside columns small-12 medium-3 large-4 p-r-6">
                    <div class="f-s-5 m-b-2 p-b-1">{{ trans('Included Hubs') }}</div>
                    <p class="c-muted f-s-3 m-b-1">{{ trans('See a list of your included hubs. You can remove a hub, edit the hub visibility settings and re-order your hubs.') }}</p>
                </div>
                <div class="panel-content columns small-12 medium-9 large-8">
                    <div class="slat-group m-b-5">
                        <div class="slat-group-content">
                            <div class="slat m-t-4 m-b-3 m-t-0" v-for="(item, index) in selected" v-bind:key="item.id">
                                <div class="row row-compress row-middle br p-t-3 p-b-3">
                                    <div class="slat-figure--medium column small-12">
                                        <img v-bind:src="item.image ? item.image_url.small : item.placeholder_image_url" v-bind:alt="item.title" />
                                    </div>
                                    <div class="slat-content column p-l-0">
                                        <div class="row row-compress row-middle">
                                            <h4 class="card-title c-primary f-s-4 m-b-0">{{ item.title }}</h4>
                                            <div class="m-l-2">
                                                <span v-if="item.status === 'draft'" class="badge f-w-bold f-caps c-bright c-muted-bg">{{ trans('Draft')  }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slat-aside text-right m-r-4">
                                        <span class="tooltip animate">
                                            <a class="i-container i-filled-dark" v-on:click.prevent="removeItem(index)">
                                                <!-- /icons/remove.svg -->
                                                <svg class="icon i-size-medium inline" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"/><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"/></g></svg>
                                            </a>
                                            <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                                        </span>
                                    </div>
                                    <div class="columns m-n-b-3">
                                        <div class="br-top m-t-3 p-t-1"></div>
                                        <div class="row row-collapse m-b-1">
                                            <div class="column text-left p-t-1">
                                                <span class="i-container i-filled-dark m-r-2">
                                                    <!-- /icons/previewable.svg -->
                                                    <svg class="icon icon-expand i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(4 6)" class="icon"><path d="M8 12c4.707 0 7.744-5.284 7.871-5.508a1 1 0 0 0 .001-.98C15.746 5.287 12.731 0 8 0 3.245 0 .251 5.289.126 5.514a.998.998 0 0 0 .002.975C.254 6.713 3.269 12 8 12zM8 2c2.839 0 5.036 2.835 5.818 4-.784 1.166-2.981 4-5.818 4-2.841 0-5.038-2.838-5.819-4.001C2.958 4.835 5.146 2 8 2z"/><circle cx="8" cy="6" r="2"/></g></svg>
                                                </span>
                                                <span class="c-medium-tint f-s-3">{{ itemAvailabilityLabel(item) }}</span>
                                                <a class="f-s-3 m-l-2 cu-p" href="#edit-visibility" v-on:click.prevent="showAvailabilityModal(item)">{{ trans('Edit Visibility') }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" v-bind:name="'items[' + index + '][id]'" v-bind:value="item.pivot.id" />
                                <input type="hidden" v-bind:name="'items[' + index + '][hub_id]'" v-bind:value="item.id" />
                                <input type="hidden" v-bind:name="'items[' + index + '][visibility]'" v-bind:value="item.pivot.visibility" />
                                <input type="hidden" v-bind:name="'items[' + index + '][content_release]'" v-bind:value="item.pivot.content_release" />
                                <input type="hidden" v-bind:name="'items[' + index + '][all_at_once]'" v-bind:value="item.pivot.all_at_once" />
                                <input type="hidden" v-bind:name="'items[' + index + '][date]'" v-bind:value="item.pivot.date" />
                                <input type="hidden" v-bind:name="'items[' + index + '][delay]'" v-bind:value="item.pivot.delay" />
                                <input type="hidden" v-bind:name="'items[' + index + '][interval]'" v-bind:value="item.pivot.interval" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <slot v-else name="empty"></slot>

        <hub-picker-modal
            v-bind:hubs="filteredHubs"
            v-on:close="hubPickerModal.show = false"
            v-on:select-hub="addHub"
            ref="hub-picker-modal"
        ></hub-picker-modal>

        <hub-availability-modal
            v-on:close="closeAvailabilityModal"
            ref="hub-availability-modal"
        ></hub-availability-modal>

        <input type="hidden" v-bind:name="'for_removal[' + index + ']'" v-for="(id, index) in forRemoval" v-bind:value="id" v-bind:key="index" />
    </div>
</template>
<script>
import { EventBus } from '../../event-bus.js';
import TranslateInterval from '../../mixins/TranslateInterval.js';
import HubPickerModal from './HubPickerModal.vue';
import HubAvailabilityModal from './HubAvailabilityModal.vue';

export default {
    components: {
        HubPickerModal,
        HubAvailabilityModal,
    },
    mixins: [
        TranslateInterval,
    ],
    props: {
        hubs: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selected: this.items,
            forRemoval: [],
        }
    },
    computed: {
        filteredHubs() {
            return this.hubs.filter((hub) => {
                return _.findIndex(this.selected, {'id': hub.id}) < 0 && _.findIndex(this.selected, {'id': hub.id + ''}) < 0;
            });
        },
    },
    methods: {
        addHub(hub) {
            this.selected.push({
                ...hub,
                pivot: {
                    id: null,
                    visibility: 'all_members',
                    content_release: 'original_drip',
                    date: null,
                    interval: 'day',
                    delay: 10
                }
            });

            this.hubPickerModal.show = false;

            EventBus.$emit('form:element:changed', 'items', this.selected.map(item => item.id).join('-'));
        },
        itemAvailabilityLabel(item) {
            let label = '';
            switch (item.pivot.visibility) {
                case 'new_members':
                    label = this.trans('New members only');
                    break;
                case 'all_members':
                default:
                    label = this.trans('All members');
            }

            switch (item.pivot.content_release) {
                case 'release_date':
                    label += this.trans(' will get the content on :date', {'date': item.pivot.date});
                    break;
                case 'drip_schedule':
                    label += this.trans(' will get content after :delay :interval(s)', {'delay': item.pivot.delay, 'interval': this.translateInterval(item.pivot.interval)});
                    break;
                case 'skip_drip':
                    label += this.trans(' will get all content immediately');
                    break;
                case 'original_drip':
                default:
                    label += this.trans(' will get content according to original drip settings');
            }

            return label;
        },
        removeItem(index) {
            this.$dialog.confirm(this.trans('Do you really want to delete a hub from a membership?'))
                .then(() => {
                    if (_.get(this.selected[index], 'id') !== null) {
                        this.forRemoval.push(this.selected[index].pivot.id);
                    }
                    this.selected.splice(index, 1);

                    EventBus.$emit('form:element:changed', 'for_removal', this.forRemoval.join('-'));
                    EventBus.$emit('form:element:changed', 'items', this.selected.map(item => item.id).join('-'));
                });
        },
        showAvailabilityModal(item) {
            this.hubAvailabilityModal.item = item;
            this.hubAvailabilityModal.show = true;
        },
        closeAvailabilityModal() {
            // Set to next tick in order to take reverted availailability settings into consideration
            this.$nextTick(() => {
                EventBus.$emit('form:check');
            });
        },
    },
    mounted() {
        this.hubPickerModal = this.$refs['hub-picker-modal'];
        this.hubAvailabilityModal = this.$refs['hub-availability-modal'];

        this.$nextTick(() => {
            EventBus.$emit('form:element:initialize', 'for_removal', this.forRemoval.join('-'));
            EventBus.$emit('form:element:initialize', 'items', this.selected.map(item => item.id).join('-'));
        });
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:new-hub', () => {
            $vm.hubPickerModal.show = true;
        });
    },
}
</script>