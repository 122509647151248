<template>
    <modal v-bind:show.sync="show" ref="modal" size="medium">
        <div slot="header"></div>

        <div class="row">
            <div class="column">
                <h4 class="add-product-heading f-s-7 m-b-2">{{ trans('To add members, you must first create a membership') }}</h4>
                <div class="alert p-3 m-b-3 m-t-4 c-muted br br-c--fade c-accent-bg-light">
                    <div class="row row-collapse">
                        <span class="i-container i-filled-theme">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="injected-svg icon inject-svg i-size-small m-r-2"><path d="M12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 13c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1.5-4.6c-.5.3-.5.4-.5.6v1h-2v-1c0-1.3.8-1.9 1.4-2.3.5-.3.6-.4.6-.7 0-.6-.4-1-1-1-.4 0-.7.2-.9.5l-.5.9-1.7-1 .5-.9C9.9 7.6 10.9 7 12 7c1.7 0 3 1.3 3 3 0 1.4-.9 2-1.5 2.4z" class="icon"></path></svg>
                        </span>
                        <div class="column">
                            <p class="m-0">
                                {{ trans("You need to create a membership before you can add members.") }}
                                <a href="https://help.kourses.com/en/article/6-what-is-a-membership" target="_blank">{{ trans('Learn more about memberships here.') }}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" v-on:click="close" class="btn btn-primary m-r-3">{{ trans("Close") }}</button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import { EventBus } from '../../event-bus.js';
import Modal from '../modals/Modal.vue';

export default {
    components: {
        Modal,
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        close() {
            this.show = false;
        }
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:add-memberships', () => {
            $vm.show = true;
        });
    },
}
</script>