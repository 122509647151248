<template>
    <div role="dialog">
        <transition name="modal-fade">
            <div v-show="show" ref="container" class="modal-container show" v-bind:id="id">
                <a v-if="showCloseButton" v-on:click.prevent="close" class="modal-close close i-container p-2 rounded-xlarge c-lighter-bg i-filled-dark" href="#close-modal">
                    <!-- /icons/close.svg -->
                    <svg class="icon icon-dark i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M19 3.586L3.586 19 5 20.414 20.414 5z"/><path d="M20.414 19L5 3.586 3.586 5 19 20.414z"/></g></svg>
                </a>
                <div v-bind:class="'box-' + size" class="modal p-t-5 p-b-5 c-bright-bg panel">
                    <header class="modal-header">
                        <div class="row">
                            <div class="small-12 columns">
                                <slot name="header">
                                    <h3 class="panel-title f-s-5">{{ title }}</h3>
                                </slot>
                            </div>
                        </div>
                    </header>
                    <div class="modal-content">
                        <div class="m-t-3">
                            <div class="row">
                                <div class="small-12 columns">
                                    <slot></slot>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer m-t-5">
                        <div class="row">
                            <div class="small-12 columns">
                                <slot name="footer">
                                    <div class="row row-collapse">
                                        <div class="column">
                                            <a class="btn btn-primary m-r-3 modal-close-external" href="#save">{{ trans('Save') }}</a>
                                        </div>
                                    </div>
                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    export default {
        props: {
            id: {
                type: String
            },
            title: {
                type: String
            },
            showCloseButton: {
                type: Boolean,
                default: true
            },
            show: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: 'small'
            }
        },
        watch: {
            show(value) {
                if (value === true) {
                    this.$parent.$emit('open');
                    if ( ! document.body.classList.contains('modaled')) {
                        document.body.classList.add('modaled');
                    }
                } else {
                    if (document.body.classList.contains('modaled')) {
                        document.body.classList.remove('modaled');
                    }

                    // Reset the modal scroll position
                    this.$refs['container'].scrollTop = 0;
                }
            }
        },
        methods: {
            close() {
                this.$emit('update:show', false);
                this.$parent.$emit('close');

                if (document.body.classList.contains('modaled')) {
                    document.body.classList.remove('modaled');
                }

                // Reset the modal scroll position
                this.$refs['container'].scrollTop = 0;
            }
        },
        mounted() {
            if (this.$parent.show === true && ! document.body.classList.contains('modaled')) {
                document.body.classList.add('modaled');
            }
        }
    }
</script>