<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 v-if="index !== null" class="panel-title f-s-5">{{ trans('Edit Coupon') }}</h3>
            <h3 v-else class="panel-title f-s-5">{{ trans('Create Coupon') }}</h3>
        </div>

        <div class="row row-collapse row-collapse-indent">
            <div class="columns">
                <div class="form-group" v-bind:class="{'has-error': errors.has('name')}">
                    <label>
                        {{ trans('Name') }}
                        <input type="text" v-model="item.name" required>
                    </label>
                </div>
                <div v-if="errors.has('name')" class="form-help">
                    {{ errors.get('name') }}
                </div>

                <div class="form-group" v-bind:class="{'has-error': errors.has('code')}">
                    <label>
                        {{ trans('Code') }}
                        <input type="text" v-model="item.code" required>
                    </label>
                </div>
                <div v-if="errors.has('code')" class="form-help">
                    {{ errors.get('code') }}
                </div>
            </div>
        </div>

        <div class="row row-collapse row-collapse-indent m-t-4">
            <div class="columns">
                <div class="form-group">
                    <label>
                        {{ trans('Which membership to apply the coupon') }}
                        <select v-model="item.membership_id">
                            <option v-bind:value="null">{{ trans('All memberships') }}</option>
                            <option v-bind:value="membership.id">{{ trans('This course only (:membership)', {'membership': membership.name}) }}</option>
                        </select>
                    </label>
                </div>

                <div v-if="item.membership_id !== null">
                    <div v-if="selectedMembershipHasRecurringPlans" class="form-group">
                        <label>
                            {{ trans('Apply this coupon to') }}
                            <select v-model="item.duration">
                                <option value="once">{{ trans('First payment (Once)') }}</option>
                                <option value="forever">{{ trans('All payments (Forever)') }}</option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-collapse row-collapse-indent m-t-4"><!-- type -->
            <div class="column medium-6">
                <div class="form-group">
                    <label>
                        {{ trans('Type') }}
                        <select v-model="item.type">
                            <option value="percentage">{{ trans('Percentage off') }}</option>
                            <option value="amount">{{ trans('Fixed amount off') }}</option>
                        </select>
                    </label>
                </div>
            </div>
            <div class="column medium-6">
                <div v-if="item.type === 'percentage'" class="form-group form-group-addon" v-bind:class="{'has-error': errors.has('value')}">
                    <label>
                        {{ trans('Percentage off') }}
                        <input class="input__field" type="number" v-model="item.value" placeholder="0" min="0" max="100">
                    </label>
                    <span class="btn form-append btn-text width-auto-min">%</span>
                </div>
                <div v-if="item.type === 'amount'" class="form-group form-group-addon" v-bind:class="{'has-error': errors.has('value')}">
                    <label>
                        {{ trans('Fixed amount off') }}
                        <input class="input__field" type="number" step="any" v-model="item.value" placeholder="0">
                    </label>
                    <span class="btn form-append btn-text width-auto-min">{{ item.currency }}</span>
                </div>
                <div v-if="errors.has('value')" class="form-help">
                    {{ errors.get('value') }}
                </div>
            </div>
        </div><!-- type -->

        <div v-if="item.type === 'percentage'" class="form-group" v-bind:class="{'has-error': errors.has('applies_to')}">
            <label>
                {{ trans('Applies to') }}
                <select v-model="item.applies_to">
                    <option value="main_only">{{ trans('Main product only') }}</option>
                    <option value="main_bumps">{{ trans('Main product and bumps') }}</option>
                </select>
            </label>
        </div>
        <div v-if="errors.has('applies_to')" class="form-help">{{ errors.get('applies_to') }}</div>

        <div class="row row-collapse row-collapse-indent m-t-4">
            <div class="column medium-6"><!-- starts -->
                <div class="form-group">
                    <label>
                        {{ trans('Starts') }}
                        <select v-model="starts">
                            <option value="immediately">{{ trans('Immediately') }}</option>
                            <option value="date">{{ trans('On exact date') }}</option>
                        </select>
                    </label>
                </div>

                <div v-if="starts === 'date'" class="form-group">
                    <label>
                        {{ trans('Starting on') }}
                        <date-pick
                            v-model="item.starts_at"
                            v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick a date')}"
                            v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                            v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                            v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                            v-bind:weekdays="datePickLabels.weekdays"
                            v-bind:months="datePickLabels.months"
                            v-bind:isDateDisabled="startingDateDisabledCheck"
                        ></date-pick>
                    </label>
                </div>
            </div><!-- starts -->

            <div class="column medium-6"><!-- ends -->
                <div class="form-group">
                    <label>
                        {{ trans('Ends') }}
                        <select v-model="ends">
                            <option value="indefinitely">{{ trans('Until deactivated/deleted') }}</option>
                            <option value="date">{{ trans('On exact date & time') }}</option>
                        </select>
                    </label>
                </div>

                <div v-if="ends === 'date'" class="form-group">
                    <label>
                        {{ trans('Expiring on') }}
                        <date-pick
                            v-model="item.expires_at"
                            v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick a date and time')}"
                            v-bind:pickTime="true"
                            v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                            v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                            v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                            v-bind:weekdays="datePickLabels.weekdays"
                            v-bind:months="datePickLabels.months"
                            v-bind:isDateDisabled="expiringDateDisabledCheck"
                            format="YYYY-MM-DD HH:mm"
                        ></date-pick>
                    </label>
                </div>
            </div><!-- ends -->
        </div>

        <div class="row row-collapse row-collapse-indent m-t-4">
            <div class="column medium-6">
                <div class="form-group">
                    <autocomplete
                        v-bind:items="item.member_id ? [item.member_id] : []"
                        v-bind:suggestions="members"
                        v-bind:multiple="false"
                        v-on:change="updateMemberId"
                        name="tags"
                        v-bind:label="trans('Limit to member')"
                    ></autocomplete>
                </div>
            </div>

            <div class="column medium-6">
                <div class="form-group">
                    <label>
                        {{ trans('Max redemptions') }}
                        <input type="type" v-model="item.max_redemptions">
                    </label>
                </div>
                <div class="form-help">
                    {{ trans("Leave empty or '0' for unlimited") }}
                </div>
            </div>
        </div>

        <div slot="footer" class="p-b-6">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="saveItem" v-bind:class="{'btn-loading': loading}">
                        {{ trans('Save') }}
                        <div v-if="loading" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from './Modal.vue';
import Errors from '../../errors.js';
import { EventBus } from '../../event-bus.js';
import AutoComplete from '../fields/AutoComplete.vue';
import DatePick from 'vue-date-pick';
import DatePickLocalizationMixin from '../fields/DatePickLocalizationMixin.vue';

export default {
    components: {
        Modal,
        AutoComplete,
        DatePick
    },
    mixins: [
        DatePickLocalizationMixin
    ],
    props: {
        members: {
            type: Object
        },
        membership: {
            type: Object
        },
    },
    data() {
        return {
            item: {
                id: null,
                name: '',
                code: '',

                member_id: null,
                membership_id: null,

                type: 'percentage',
                value: null,
                currency: window.Kourses.currency,
                max_redemptions: null,
                duration: 'forever',
                applies_to: 'main_only',

                starts_at: null,
                expires_at: null,
            },
            show: false,
            index: null,
            ends: 'indefinitely',
            starts: 'immediately',
            errors: new Errors(),
            loading: false,
        }
    },
    computed: {
        selectedMembershipHasRecurringPlans() {
            if (this.item.membership_id === null) {
                return false;
            }

            return this.membership.pricing_plans.some((plan) => ['subscription', 'installment-plan'].includes(plan.type));
        },
    },
    watch: {
        'item.membership_id': {
            handler(newMembershipId) {
                if (newMembershipId !== '' && newMembershipId !== null) {
                    return;
                }

                this.item.currency = this.getMembershipCurrency();
            }
        },
        starts(newValue) {
            if (newValue === 'immediately') {
                this.item.starts_at = null;
            }
        },
        ends(newValue) {
            if (newValue === 'indefinitely') {
                this.item.expires_at = null;
            }
        },
    },
    methods: {
        getMembershipCurrency() {
            if (this.membership.pricing_plans && this.membership.pricing_plans.length > 0) {
                return this.membership.pricing_plans[0].currency;
            }

            return window.Kourses.currency;
        },

        reset() {
            this.index = null;
            this.ends = 'indefinitely';
            this.starts = 'immediately';
            this.errors.clear();
            this.loading = false;
            this.item = {
                id: null,
                name: '',
                code: '',

                member_id: null,
                membership_id: null,

                type: 'percentage',
                value: null,
                currency: window.Kourses.currency,
                max_redemptions: null,
                duration: 'forever',
                applies_to: 'main_only',

                starts_at: null,
                expires_at: null,
            };
        },
        close() {
            this.reset();
            this.show = false;
        },
        saveItem() {
            this.loading = true;
            this.errors.clear();

            if (this.index !== null) {
                window.axios.put(Kourses.apiBaseUrl + '/coupons/' + this.item.encoded_id, this.item).then((response) => {
                    this.show = false;
                    this.$emit('coupon:updated', response.data, this.index);
                    this.reset();
                }).catch((error) => {
                    this.loading = false;

                    if (error.response.status === 422) {
                        // Handle validation errors
                        this.errors.record(error.response.data);
                    } else {
                        // Handle general errors
                        this.$toast.danger(error.response.data.message);
                    }
                });
            } else {
                window.axios.post(Kourses.apiBaseUrl + '/coupons', this.item).then((response) => {
                    this.show = false;
                    this.$emit('coupon:added', response.data, this.index);
                    this.reset();
                }).catch((error) => {
                    this.loading = false;

                    if (error.response.status === 422) {
                        // Handle validation errors
                        this.errors.record(error.response.data);
                    } else {
                        // Handle general errors
                        this.$toast.danger(error.response.data.message);
                    }
                });
            }
        },
        updateMemberId(memberId) {
            if (memberId && memberId.length > 0) {
                this.item.member_id = parseInt(memberId[0], 10);
            } else {
                this.item.member_id = null;
            }
        },
        toggleProductId(status) {
            if (status) {
                this.item.membership_id = this.membership.id;
            } else {
                this.item.membership_id = null;
            }
        },
        startingDateDisabledCheck(date) {
            if (this.item.expires_at !== null && new Date(this.item.expires_at) < date) {
                return true;
            }

            return false;
        },
        expiringDateDisabledCheck(date) {
            if (this.item.starts_at !== null && new Date(this.item.starts_at) > date) {
                return true;
            }

            return false;
        }
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:new-coupon', () => {
            $vm.reset();
            $vm.show = true;
        });

        $vm.$on('close', () => {
            $vm.close();
        })
    },
}
</script>