<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Add Order Bump') }}</h3>
        </div>

        <!-- Membership picker -->
         <div v-bind:class="{'has-error': errors.has('membership_id')}" class="form-group form-group-simple">
            <label class="m-b-2">{{  trans('Membership') }}</label>
            <div v-if="selectedMembership" v-bind:key="selectedMembership.id" class="card br row m-b-6">
                <div class="column">
                    <div class="card-content row row-collapse-indent row-middle m-t-4 m-b-4">
                        <div class="slat-content column">
                            <h4 class="card-title c-primary f-s-4 m-b-0">{{ selectedMembership.name }}</h4>
                            <div class="card-type f-s-2 c-muted m-t-1">{{ membershipHubs(selectedMembership) }}</div>
                        </div>
                        <div class="slat-aside text-right m-r-4">
                            <a v-on:click.prevent="deleteMembership" class="i-container i-filled-dark btn btn-text width-auto-min p-0 m-r-0" href="#remove-membership">
                                <!-- /icons/remove.svg -->
                                <svg class="icon i-size-medium" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"/><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"/></g></svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if=" ! selectedMembership" class="text-center">
                <button v-on:click="addMembership" type="button" class="btn btn-text btn-text--accent btn-icon m-b-4">
                    <span class="icon i-container i-filled-accent">
                        <!-- /icons/add.svg -->
                        <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M11 5v14h2V5z"/><path d="M19 11H5v2h14z"/></g></svg>
                    </span>
                    {{ trans('Add Membership') }}
                </button>
            </div>
         </div>
         <div v-if="errors.has('membership_id')" class="form-help">{{ errors.get('membership_id') }}</div>

        <!-- Pre Headline -->
        <div v-bind:class="{'has-error': errors.has('pre_headline')}" class="form-group">
            <label>
                {{ trans('Order Bump Box Pre-Headline') }}
                <input class="input__field" type="text" v-model="bump.pre_headline">
            </label>
        </div>
        <div v-if="errors.has('pre_headline')" class="form-help">{{ errors.get('pre_headline') }}</div>

        <!-- Headline -->
        <div v-bind:class="{'has-error': errors.has('headline')}" class="form-group">
            <label>
                {{ trans('Order Bump Box Headline') }}
                <input class="input__field" type="text" v-model="bump.headline">
            </label>
        </div>
        <div v-if="errors.has('headline')" class="form-help">{{ errors.get('headline') }}</div>

        <!-- Description -->
        <div class="form-group form-group-simple">
            <label class="m-b-2">{{ trans('Description') }}</label>
            <rich-text-editor
                id="bump_description"
                name="bump[description]"
                v-bind:key="'rte-' + index"
                v-bind:content="bump.description"
                v-on:input="bump.description = $event"
            ></rich-text-editor>
        </div>

        <!-- Features -->
        <bullets
            field-name="bump[features]"
            v-bind:key="'bullets-' + index"
            v-bind:data="{'items': bump.features}"
            v-bind:items-label="trans('Product Highlights Bullets')"
            v-bind:has-heading-label="false"
            v-on:update:items="updateFeatureItems"
        ></bullets>

        <!-- Image -->
        <div class="form-group form-group-simple">
            <label class="m-b-2">{{  trans('Image') }}</label>
            <image-upload
                field-name="image"
                v-bind:existing-file-url="bump.image_url"
                v-bind:existing-file-path="bump.image"
                v-bind:max-filesize="2"
                v-on:image-selected="addImage"
                v-on:image-removed="removeImage"
            ></image-upload>
            <div v-if=" ! bump.image" class="c-muted f-s-1 m-t-1">{{ trans('Make this image JPEG, PNG, GIF, BMP or WEBP; 16:9 ratio and at least 1024x576 pixels.') }}</div>
        </div>

        <!-- Button Label -->
        <div v-bind:class="{'has-error': errors.has('button_label')}" class="form-group">
            <input-with-vars
                class="input__field"
                name="button_label"
                v-bind:label="trans('Order Bump Add to Cart Button')"
                v-bind:variables="{'price': trans('Price')}"
                v-model="bump.button_label"
                v-on:update:value="bump.button_label = $event"
            ></input-with-vars>
        </div>
        <div v-if="errors.has('button_label')" class="form-help">{{ errors.get('button_label') }}</div>

        <!-- Pricing -->
        <div class="row row-collapse-indent">
            <div v-bind:class="{'small-4': bump.pricing_plan.type === 'subscription', 'small-6': bump.pricing_plan.type === 'one-time'}" class="column">
                <div class="form-group">
                    <label>{{ trans('Payment type') }}</label>
                    <select v-on:change="generateName" class="input__field" v-model="bump.pricing_plan.type">
                        <option v-for="type in allowedBumpTypes" v-bind:value="type" v-bind:key="type">{{ typeLabels[type] }}</option>
                    </select>
                </div>
            </div>
            <div v-bind:class="{'small-4': bump.pricing_plan.type === 'subscription', 'small-6': bump.pricing_plan.type === 'one-time'}" class="column">
                <div v-bind:class="{'has-error': errors.has('pricing_plan.price')}" class="form-group form-group-addon">
                    <label>
                        {{ trans('Price') }}
                        <input class="input__field" type="number" step="any" v-model="bump.pricing_plan.price" placeholder="0">
                    </label>
                    <span class="btn form-append btn-text width-auto-min">{{ currency }}</span>
                </div>
            </div>
            <div v-if="bump.pricing_plan.type === 'subscription'" v-bind:class="{'small-4': bump.pricing_plan.type === 'subscription', 'small-6': bump.pricing_plan.type === 'one-time'}" class="column">
                <div class="form-group">
                    <label>{{ trans('Every') }}</label>
                    <select v-on:change="generateName" class="input__field" v-model="bump.pricing_plan.interval">
                        <option v-for="(label, key) in intervals" v-bind:value="key" v-bind:key="key">{{ label }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div v-if="errors.has('pricing_plan.price')" class="form-help">{{ errors.get('pricing_plan.price') }}</div>

        <div v-if="allowedBumpTypes.length === 1" class="alert p-3 m-b-3 c-muted br br-c--fade alert c-warning-bg">
            <div class="row row-collapse">
                <span class="i-container i-filled-error">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="injected-svg icon inject-svg i-size-small m-r-2">
                        <path d="M12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 13c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1.5-4.6c-.5.3-.5.4-.5.6v1h-2v-1c0-1.3.8-1.9 1.4-2.3.5-.3.6-.4.6-.7 0-.6-.4-1-1-1-.4 0-.7.2-.9.5l-.5.9-1.7-1 .5-.9C9.9 7.6 10.9 7 12 7c1.7 0 3 1.3 3 3 0 1.4-.9 2-1.5 2.4z" class="icon"></path>
                    </svg>
                </span>
                <div class="column">
                    <p class="m-0">
                        {{ trans('You can only create this bump with one-time payment pricing, as this membership already includes a pricing plan or bump subscription.') }}
                        <a href="https://help.kourses.com/en/article/87-how-to-sell-order-bumps-on-your-checkout" target="_blank">{{  trans('Learn more.') }}</a>
                    </p>
                </div>
            </div>
        </div>

        <!-- Name -->
        <div v-if="bump.pricing_plan.type === 'subscription'" v-bind:class="{'has-error': errors.has('pricing_plan.name')}" class="form-group">
            <label>
                {{ trans('Subscription Description (shown on checkout summary)') }}
                <input class="input__field" type="text" v-model="bump.pricing_plan.name" v-on:keyup="manuallyChangedName = true">
            </label>
        </div>
        <div v-if="errors.has('pricing_plan.name')" class="form-help">{{ errors.get('pricing_plan.name') }}</div>

        <!-- Original Price -->
        <div class="form-group form-group-addon">
            <label>
                {{ trans('Original Price') }}
                <input class="input__field" type="number" step="any" v-model="bump.original_price" placeholder="0">
            </label>
            <span class="btn form-append btn-text width-auto-min">{{ currency }}</span>
        </div>

        <div slot="footer" class="p-b-6">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="saveBump">{{ trans('Save') }}</button>
                </div>
                <div class="text-right">
                    <button type="button" class="btn btn-outline btn-outline--alert" v-on:click="deleteBump">{{ trans('Delete') }}</button>
                </div>
            </div>
        </div>

        <membership-picker-modal
            v-bind:memberships="memberships"
            v-on:select-membership="selectMembership"
            ref="membership-picker-modal"
        ></membership-picker-modal>
    </modal>
</template>
<script>
import Modal from '../modals/Modal.vue';
import MembershipPickerModal from '../memberships/MembershipPickerModal.vue';
import HasMembershipHubs from '../../mixins/HasMembershipHubs.js';
import Errors from '../../errors.js';
import {
    PLAN_TYPE_ONE_TIME, PLAN_TYPE_SUBSCRIPTION,
    PLAN_INTERVAL_DAY, PLAN_INTERVAL_WEEK, PLAN_INTERVAL_YEAR, PLAN_INTERVAL_MONTH, PLAN_INTERVAL_QUARTER, PLAN_INTERVAL_2_WEEKS, PLAN_INTERVAL_6_MONTHS
} from '../../constants.js';
import { bumpValidationUrl } from '../../routes.js';
import { scrollToError } from '../../helpers.js';

export default {
    mixins: [
        HasMembershipHubs,
    ],
    components: {
        Modal,
        MembershipPickerModal
    },
    props: {
        memberships: {
            type: Array,
            required: true,
        },
        allowedTypes: {
            type: Array,
            required:true,
        },
        parent: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            show: false,
            modal: null,
            bump: this.getEmptyBump(),
            selectedMembership: null,
            errors: new Errors(),
            currency: window.Kourses.currency,
            typeLabels: {
                [PLAN_TYPE_ONE_TIME]: this.trans('One-time Payment'),
                [PLAN_TYPE_SUBSCRIPTION]: this.trans('Subscription'),
            },
            intervals: {
                [PLAN_INTERVAL_DAY]: this.trans('day'),
                [PLAN_INTERVAL_WEEK]: this.trans('week'),
                [PLAN_INTERVAL_2_WEEKS]: this.trans('2 weeks'),
                [PLAN_INTERVAL_MONTH]: this.trans('month'),
                [PLAN_INTERVAL_QUARTER]: this.trans('quarter'),
                [PLAN_INTERVAL_6_MONTHS]: this.trans('6 months'),
                [PLAN_INTERVAL_YEAR]: this.trans('year'),
            },
            index: null,
            processing: false,
            allowedBumpTypes: this.allowedTypes,
            manuallyChangedName: false,
        }
    },
    watch: {
        show(newValue) {
            if (newValue === false) {
                this.bump = this.getEmptyBump();
                this.selectedMembership = null;
                this.index = null;
                this.errors.clear();
            }
        },
        bump(newValue) {
            if (newValue.pricing_plan.type === PLAN_TYPE_SUBSCRIPTION) {
                this.allowedBumpTypes.push(PLAN_TYPE_SUBSCRIPTION);
            }
        },
    },
    methods: {
        addMembership() {
            this.modal.show = true;
        },
        selectMembership(membership) {
            this.selectedMembership = membership;
            this.bump.membership_id = membership.id;
        },
        updateFeatureItems(items) {
            this.bump.features = items;
        },
        deleteBump() {
            this.$emit('delete', this.index);
            this.show = false;
        },
        saveBump() {
            this.errors.clear();
            this.processing = true;

            return window.axios.post(bumpValidationUrl(this.parent.encoded_id), this.bump)
                .then(() => {
                    this.processing = false;
                    this.$emit('save', this.bump, this.index);
                    this.show = false;
                })
                .catch((error) => {
                    this.processing = false;
                    this.errors.record(error.response.data);

                    this.$nextTick(() => {
                        scrollToError(this.$el);
                    });
                });
        },
        deleteMembership() {
            this.selectedMembership = null;
            this.modal.show = false;
        },
        loadBump(data) {
            this.bump = data;

            if (data.membership_id) {
                this.selectedMembership = this.memberships.find((membership) => membership.id === data.membership_id);
            }
        },
        getEmptyBump() {
            return {
                encoded_id: null,
                membership_id: null,
                name: null,
                pre_headline: null,
                headline: null,
                description: null,
                features: [],
                image: null,
                image_url: null,
                button_label: this.trans('Add to my order'),
                original_price: null,
                pricing_plan: {
                    name: this.trans('One-Time'),
                    type: PLAN_TYPE_ONE_TIME,
                    interval: PLAN_INTERVAL_MONTH,
                    interval_count: 1,
                    price: null,
                    currency: window.Kourses.currency,
                },
            };
        },
        addImage(image) {
            this.bump.image = image.path;
            this.bump.image_url = image.url;
        },
        removeImage() {
            this.bump.image = null;
            this.bump.image_url = null;
        },
        /**
         * Generate plan name based on type and interval.
         *
         * @return  {String}
         */
        generateName() {
            if (this.manuallyChangedName) {
                return;
            }

            let newName = this.trans('Free');

            debugger;

            if (this.bump.pricing_plan.type === PLAN_TYPE_ONE_TIME) {
                newName = this.trans('One-Time');
            } else if (this.bump.pricing_plan.type === PLAN_TYPE_SUBSCRIPTION) {
                if (this.bump.pricing_plan.interval === 'day') {
                    newName = this.trans('Daily Subscription');
                } else if (this.bump.pricing_plan.interval === 'week') {
                    newName = this.trans('Weekly Subscription');
                } else if (this.bump.pricing_plan.interval === '2-weeks') {
                    newName = this.trans('Bi-weekly Subscription');
                } else if (this.bump.pricing_plan.interval === 'month') {
                    newName = this.trans('Monthly Subscription');
                } else if (this.bump.pricing_plan.interval === 'quarter') {
                    newName = this.trans('Quarterly Subscription');
                } else if (this.bump.pricing_plan.interval === '6-months') {
                    newName = this.trans('Bi-annual Subscription');
                } else if (this.bump.pricing_plan.interval === 'year') {
                    newName = this.trans('Yearly Subscription');
                }
            }

            this.bump.pricing_plan.name = newName;
        },
    },
    mounted() {
        this.modal = this.$refs['membership-picker-modal'];
    }
}
</script>