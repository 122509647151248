<template>
    <div class="row">
        <aside class="column small-12 medium-3">
            <div class="card m-b-4 p-4 br">
                <div class="form-group form-group-compact">
                    <label class="sc-reader">{{ trans('Search') }}</label>
                    <div class="form-group-addon">
                        <input class="input__field input-small" type="text" v-bind:placeholder="trans('Search Comments')" v-model="query" v-on:keydown.enter="filterComments">
                        <span class="form-append i-container btn btn-text i-filled-dark width-auto-min p-r-0">
                            <!-- /icons/search.svg -->
                            <svg class="icon m-l-1 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.7 15.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S4 7.2 4 11.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S8.3 6 11.1 6s5.1 2.3 5.1 5.1-2.3 5-5.1 5z" class="icon"/></svg>
                        </span>
                    </div>
                </div>
                <div class="form-group form-group-compact">
                    <label class="sc-reader">{{ trans('Hub') }}</label>
                    <hub-and-feature-filter
                        v-bind:hubs="hubs"
                        v-bind:selected="feature"
                        v-on:update:selected="feature = $event"
                    ></hub-and-feature-filter>
                </div>
                <button v-on:click="filterComments" v-bind:class="{'btn-loading': searching}" type="button" class="btn btn-primary btn--block m-b-2">
                    {{ trans('Search') }}
                    <div v-if="searching" class="loader">
                        <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                            <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                        </div>
                    </div>
                </button>
                <div class="text-center">
                    <button v-on:click="resetFilter" type="button" class="btn btn-text btn-text--muted width-auto-min">{{ trans('Reset Filter') }}</button>
                </div>
            </div>
        </aside>
        <div class="column small-12 medium-9">
            <div v-if="loading" style="margin: 0 auto;" class="chartdonut chartdonut_animation--rotate-large chartdonut--large">
                <svg id="svgElement" width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
            </div>
            <template v-else>
                <div v-if="items.length > 0">
                    <comment
                        v-for="(item, index) in items"
                        v-bind:key="item.encoded_id"
                        v-bind:comment="item"
                        v-bind:author="item.member || author"
                        v-on:comment:reply="replyTo(item)"
                        v-on:comment:delete="deleteComment(item, index)"
                    >
                        <template slot="footer">
                            <div class="row row-middle p-t-3">
                                <div class="column">
                                    <div v-if="item.breadcrumbs.commentable.type === 'Kourses\\Lesson'" class="br-top f-s-2 c-muted p-t-3">
                                        <span class="c-medium f-w-bold">{{ item.breadcrumbs.hub.title }}</span>
                                        <span class="c-muted m-y-2">/</span>
                                        <a v-bind:href="lessonEditLink(item.breadcrumbs.commentable)" class="h-c-muted">{{ item.breadcrumbs.commentable.name }}</a>
                                    </div>
                                    <div v-else-if="item.breadcrumbs.commentable.type === 'Kourses\\Models\\HubFeature'" class="br-top f-s-2 c-muted p-t-3">
                                        <span class="c-medium f-w-bold">{{ item.breadcrumbs.hub.title }}</span>
                                        <span class="c-muted m-y-2">/</span>
                                        <a v-bind:href="contentPageEditLink(item.breadcrumbs.commentable)" class="h-c-muted">{{ trans('Content Page') }}</a>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </comment>
                    <paginator
                        v-if=" ! loading"
                        v-bind:to="paginator.to"
                        v-bind:from="paginator.from"
                        v-bind:total="paginator.total"
                        v-bind:last-page="paginator.lastPage"
                        v-bind:current-page="paginator.currentPage"
                        v-on:paginator:switch-page="changePage"
                        v-bind:full-width="true"
                    ></paginator>
                </div>
                <div v-else>
                    <p class="c-mutedf-s-4">
                        {{ trans("There are no comments at the moment!") }}
                    </p>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import Comments from './Comments.vue';
import HubAndFeatureFilter from './HubAndFeatureFilter.vue';

export default {
    components: {
        HubAndFeatureFilter
    },
    extends: Comments,
    props: {
        hubs: {
            type: Array,
            required: true,
        },
        memberId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            query: '',
            searching: false,
            feature: null,
        }
    },
    methods: {
        changePage(page, showLoadingIndicator = true) {
            // We may don't want to show loading indicator while we fetch new comments due to deleting one
            if (showLoadingIndicator === true) {
                this.loading = true;
            }

            window.axios.get(Kourses.apiBaseUrl + '/comments',{
                params: {
                    'page': page,
                    'per_page': this.perPage,
                    'member': this.memberId,
                    'commentable_id': this.commentableId,
                    'commentable_type': this.commentableType,
                    'status': this.status || null,
                    'query': this.query || null,
                }
            }).then(this.showComments);
        },
        filterComments() {
            this.searching = true;

            this.changePage(1);
        },
        showComments(response) {
            this.paginator = {
                to: response.data.meta.to,
                from: response.data.meta.from,
                total: response.data.meta.total,
                lastPage: response.data.meta.last_page,
                currentPage: response.data.meta.current_page
            };
            this.items = response.data.data;
            this.loading = false;
            this.searching = false;
        },
        resetFilter() {
            this.query = '';
            this.feature = null;

            this.filterComments();
        }
    }
}
</script>