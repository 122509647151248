<template>
    <div class="editor br rounded-ittybitty">
        <textarea v-bind:id="id" v-bind:name="name" v-model="content" ref="editor"></textarea>
    </div>
</template>
<script>
import { EventBus } from '../../event-bus.js';

export default {
    props: {
        id: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        content: {
            type: String
        },
        variables: {
            type: Object,
            default: null,
        },
        fixedToolbar: {
            type: Boolean,
            default: true,
        },
        formatting: {
            type: Array,
            default: () => ['p', 'h2', 'h3'],
        },
        plugins: {
            type: Array,
            default: () => ['alignment'],
        },
        buttons: {
            type: Array,
            default: () => ['format', 'bold', 'italic', 'underline', 'lists', 'image', 'file', 'link', 'horizontalrule'],
        },
    },
    computed: {
        variablesPluginEnabled() {
            return typeof this.variables === 'object' && this.variables !== null;
        }
    },
    mounted() {
        let $vm = this;

        $R($vm.$refs.editor, {
            plugins: this.variablesPluginEnabled ? this.plugins.concat(['custom-variables']) : this.plugins,
            variables: this.variablesPluginEnabled ? this.variables : [],
            buttons: this.buttons,
            formatting: this.formatting,
            imageUpload: window.Kourses.activeBaseUrl + '/upload/redactor?_token=' + window.Laravel.csrfToken,
            imageResizable: true,
            imagePosition: true,
            maxHeight: '300px',
            minHeight: '300px',
            toolbarFixed: this.fixedToolbar,
            callbacks: {
                changed(html) {
                    $vm.$emit('input', html);
                    // Even though it seems like we could use
                    // `this.storage.getChanges()` and remove all images
                    // that were removed it should not be done. Why? Because
                    // user might not save changes and you would delete
                    // their changes. Better way (and this is what we ended
                    // implementing) is to use bootables and updated hook
                    // to check if RTE field changed and get image
                    // differences.
                    EventBus.$emit('form:element:changed', $vm.name, html);
                }
            },
            linkNewTab: true,
            linkTitle: true,
        });
    }
};
</script>