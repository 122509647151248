<template>
    <div>
        <div v-if="hasHeadingLabel" class="form-group">
            <label>
                {{ headingLabel }}
                <input class="input__field" type="text" v-bind:name="fieldName + '[heading]'" v-model="heading">
            </label>
        </div>
        <div>
            <label class="m-b-2">{{ itemsLabel }}</label>
            <div v-sortable="{ handle: '.handle', sort: true, onUpdate: onUpdate }">
                <div v-for="(item, index) in items" v-bind:key="item.key" class="row row-bottom m-b-3">
                    <div class="i-container i-filled-dark m-b-2">
                        <span class="handle">
                            <!-- /icons/drag.svg -->
                            <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                        </span>
                    </div>
                    <div v-if="icons" class="br-bottom btn btn-small btn--sq btn-text select-icon">
                        <dropdown-menu v-bind:show-caret-icon="true">
                            <span slot="trigger" class="i-filled-primary" v-html="item.iconCode"></span>
                            <div class="row row-collapsed">
                                <a v-for="(icon, key) in icons" class="small-3 p-t-2 p-r-4 p-l-4 p-b-2 h-anim h-bg-accent--light" v-on:click.prevent="setIcon(item, key)" v-bind:key="key" href="#select-icon">
                                    <span v-html="icon" class="i-container i-filled-primary"></span>
                                </a>
                            </div>
                        </dropdown-menu>
                        <input type="hidden" v-bind:name="fieldName + '[items][' + index + '][icon]'" v-bind:value="item.icon" />
                    </div>
                    <div class="column p-r-0">
                        <div class="form-group form-group-addon m-b-0">
                            <input class="input__field" ref="bullet-text" type="text" v-bind:name="fieldName + '[items][' + index + '][text]'" v-model="item.text" v-on:keydown.enter.prevent="addItem(true)">
                            <a class="btn btn-text form-append width-auto-min" v-on:click.prevent="deleteItem(index)">
                                <span class="i-container i-filled-dark">
                                    <!-- /icons/s-trash.svg -->
                                    <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center p-t-3">
            <button type="button" class="btn btn-text btn-text--accent btn-icon ul-none m-b-4" v-on:click="addItem(true)">
                <span class="icon i-container i-filled-accent">
                    <!-- /icons/add.svg -->
                    <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M11 5v14h2V5z"/><path d="M19 11H5v2h14z"/></g></svg>
                </span>
                {{ trans('Add Bullet') }}
            </button>
        </div>
    </div>
</template>
<script>
import DropdownMenu from '../DropdownMenu.vue';

export default {
    components: {
        DropdownMenu
    },
    props: {
        fieldName: {
            type: String,
            default: 'bullets'
        },
        data: {
            type: Object,
        },
        icons: {
            type: Object
        },
        defaultIcon: {
            type: String,
            default: 'bullet'
        },
        headingLabel: {
            type: String,
            default: 'Heading'
        },
        hasHeadingLabel: {
            type: Boolean,
            default: true
        },
        itemsLabel: {
            type: String,
            required: true
        }
    },
    data() {
        let key = 0;
        return {
            items: _.get(this.data, 'items', []).map((item) => {
                item.key = ++key;
                item.icon = item.icon ? item.icon : this.defaultIcon;
                item.iconCode = this.getIcon(item.icon);
                return item;
            }),
            heading: _.get(this.data, 'heading'),
            key: key
        };
    },
    watch: {
        heading(newValue) {
            this.$emit('update:heading', newValue);
        }
    },
    methods: {
        addItem(triggerFocus) {
            this.items.push({key: ++this.key, text: '', icon: this.defaultIcon, iconCode: this.getIcon(this.defaultIcon)});

            if (triggerFocus) {
                Vue.nextTick(() => {
                    this.$refs['bullet-text'][this.items.length - 1].focus();
                });
            }
        },
        deleteItem(index) {
            this.items.splice(index, 1);
        },

        setIcon(item, key) {
            item.icon = key;
            item.iconCode = _.get(this.icons, key, this.defaultIcon);
        },
        getIcon(key) {
            return _.get(this.icons, key, _.get(this.icons, this.defaultIcon));
        },
        onUpdate(event) {
            this.items.splice(event.newIndex, 0, this.items.splice(event.oldIndex, 1)[0]);
        }
    },
    created() {
        // If there are no items add one empty item
        if (this.items.length === 0) {
            this.addItem(false);
        }

        // To trigger reactivity and data sync in page builder
        this.$emit('update:items', this.items);
    }
}
</script>