const lessonCreateLink = (hub) => {
    return window.Kourses.activeBaseUrl + '/hubs/' + hub.encoded_id + '/lessons/create';
}

const lessonEditLink = (lesson) => {
    return window.Kourses.activeBaseUrl + '/hubs/' + lesson.encoded_hub_id + '/lessons/' + lesson.encoded_id + '/edit';
}

const lessonPublishLink = (lesson) =>  {
    return window.Kourses.apiBaseUrl + '/lessons/' + lesson.encoded_id + '/publish';
}

const lessonPreviewLink = (lesson) =>  {
    return window.Kourses.apiBaseUrl + '/lessons/' + lesson.encoded_id + '/preview';
}

const lessonDeleteLink = (lesson) => {
    return window.Kourses.apiBaseUrl + '/hubs/' + lesson.encoded_hub_id + '/lessons/' + lesson.encoded_id;
}

const lessonCloneLink = () => {
    return window.Kourses.apiBaseUrl + '/lessons/clone';
}

const moduleStoreLink = (hub) => {
    return window.Kourses.activeBaseUrl + '/hubs/' + hub.encoded_id + '/modules';
}

const moduleUpdateLink = (module) => {
    return window.Kourses.activeBaseUrl + '/hubs/' + module.encoded_hub_id + '/modules/' + module.encoded_id;
}

const moduleDeleteLink = (module) => {
    return window.Kourses.activeBaseUrl + '/hubs/' + module.encoded_hub_id + '/modules/' + module.encoded_id;
}

const moduleCloneLink = (module) => {
    return window.Kourses.apiBaseUrl + '/modules/' + module.encoded_id + '/clone';
}

const couponShowLink = (coupon) => {
    return window.Kourses.activeBaseUrl + '/coupons/' + coupon.encoded_id;
}

const memberShowLink = (member) => {
    return window.Kourses.activeBaseUrl + '/members/' + member.encoded_id;
}

const memberEditLink = (member) => {
    return window.Kourses.activeBaseUrl + '/members/' + member.encoded_id + '/edit';
}

const memberDeleteLink = (member) => {
    return window.Kourses.activeBaseUrl + '/members/' + member.encoded_id;
}

const memberSendResetPasswordLink = (member) => {
    return window.Kourses.apiBaseUrl + '/members/' + member.encoded_id + '/reset-password';
}

const memberSendActivationLinkLink = (member) => {
    return window.Kourses.apiBaseUrl + '/members/' + member.encoded_id + '/activation-link';
}

const membersInMembershipShowLink = (membership) => {
    return window.Kourses.activeBaseUrl + '/members/?membership=' + membership.encoded_id;
}

const membersInHubShowLink = (hub) => {
    return window.Kourses.activeBaseUrl + '/members/?hub=' + hub.encoded_id;
}

const hubShowLink = (hub) => {
    return window.Kourses.activeBaseUrl + '/hubs/' + hub.encoded_id;
}

const hubEditLink = (hub) => {
    return window.Kourses.activeBaseUrl + '/hubs/' + hub.encoded_id + '/edit';
}

const hubDeleteLink = (hub) => {
    return window.Kourses.activeBaseUrl + '/hubs/' + hub.encoded_id;
}

const hubCloneLink = (hub) => {
    return window.Kourses.apiBaseUrl + '/hubs/' + hub.encoded_id + '/clone';
}

const membershipEditLink = (membership) => {
    return window.Kourses.activeBaseUrl + '/memberships/' + membership.encoded_id + '/edit';
}

const membershipDeleteLink = (membership) => {
    return window.Kourses.activeBaseUrl + '/memberships/' + membership.encoded_id;
}

const membershipCloneLink = (membership) => {
    return window.Kourses.apiBaseUrl + '/memberships/' + membership.encoded_id + '/clone';
}

const membershipCheckoutUrl = (membership) => {
    return membership.checkout_url;
}

const membershipTestCheckoutUrl = (membership) => {
    return membership.checkout_preview_url;
}

const membershipIntegrationsLink = (membership) => {
    return window.Kourses.apiBaseUrl + '/memberships/' + membership.encoded_id + '/integrations';
}

const membershipExtraFieldsUrl = (integrationUid, listId) => {
    return window.Kourses.apiBaseUrl + '/integrations/' + integrationUid + '/extra-fields/' + listId;
}

const contentPageEditLink = (contentPageFeature) => {
    return window.Kourses.activeBaseUrl + '/hubs/' + contentPageFeature.encoded_hub_id + '/content-page/' + contentPageFeature.encoded_id;
}

const offerEditLink = (offer) => {
    return window.Kourses.activeBaseUrl + '/website/promos/' + offer.encoded_id + '/edit';
}

const offerDeleteLink = (offer) => {
    return window.Kourses.apiBaseUrl + '/website/promos/' + offer.encoded_id;
}

const hubFeatureEditLink = (hubFeature) => {
    return window.Kourses.activeBaseUrl + '/hubs/' + hubFeature.encoded_hub_id + '/' + hubFeature.type + '/' + hubFeature.encoded_id;
}

const postShowLink = (post) => {
    return window.Kourses.activeBaseUrl + '/goto/website/?redirect=' + encodeURIComponent('post/' + post.slug);
}
const postEditLink = (post) => {
    return window.Kourses.activeBaseUrl + '/hubs/' + post.encoded_hub_id + '/posts/' + post.encoded_id + '/edit';
}
const postDeleteLink = (post) => {
    return window.Kourses.apiBaseUrl + '/posts/' + post.encoded_id;
}
const postLikeLink = (post) => {
    return window.Kourses.apiBaseUrl + '/likes/post/' + post.encoded_id;
}

const blocklistIndexUrl = () => {
    return window.Kourses.apiBaseUrl + '/settings/blocklist';
}
const blocklistDeleteUrl = (blocklist) => {
    return window.Kourses.apiBaseUrl + '/settings/blocklist/' + blocklist.encoded_id;
}
const blocklistStoreUrl = () => {
    return window.Kourses.apiBaseUrl + '/settings/blocklist';
}

const taxReportBaseUrl = () => {
    return window.Kourses.activeBaseUrl + '/sales/tax-reports';
}

const bumpValidationUrl = (parentMembershipId) => {
    return window.Kourses.apiBaseUrl + '/memberships/' + parentMembershipId + '/bumps/validate';
}

const integrationEmailConnectUrl = (integration) => {
    return Kourses.apiBaseUrl + '/integrations/emails/' + integration.provider;
}

export {
    lessonCreateLink,
    lessonEditLink,
    lessonDeleteLink,
    lessonPublishLink,
    lessonPreviewLink,
    lessonCloneLink,
    moduleStoreLink,
    moduleDeleteLink,
    moduleUpdateLink,
    moduleCloneLink,
    couponShowLink,
    memberShowLink,
    memberEditLink,
    memberDeleteLink,
    memberSendResetPasswordLink,
    memberSendActivationLinkLink,
    membersInHubShowLink,
    membersInMembershipShowLink,
    hubShowLink,
    hubEditLink,
    hubDeleteLink,
    hubCloneLink,
    membershipEditLink,
    membershipDeleteLink,
    membershipCloneLink,
    membershipIntegrationsLink,
    membershipExtraFieldsUrl,
    membershipCheckoutUrl,
    membershipTestCheckoutUrl,
    contentPageEditLink,
    offerEditLink,
    offerDeleteLink,
    hubFeatureEditLink,
    postShowLink,
    postEditLink,
    postDeleteLink,
    postLikeLink,
    blocklistIndexUrl,
    blocklistDeleteUrl,
    blocklistStoreUrl,
    taxReportBaseUrl,
    bumpValidationUrl,
    integrationEmailConnectUrl,
}