<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header"></div>

        <div v-if="hasCourse">
            <h3 class="panel-title f-s-5">{{ trans('Release Schedule') }}</h3>
            <div class="select-group">
                <div class="select-group-item">
                    <div class="control-group control-group--compact--large">
                        <label class="control control-radio block p-l-5">
                            <span class="i-container i-filled-theme p-r-5 p-l-4">
                                <img class="icon i-size-medium" v-bind:src="assetUrl('img/icons/bundle-v-lg-3.png')">
                            </span>
                            <span class="f-s-5">{{ trans('Use Original Drip Schedule') }}</span>
                            <input type="radio" value="original_drip" v-model="item.pivot.content_release">
                            <div class="control-indicator control-radio-indicator"></div>
                        </label>
                    </div>
                </div>
                <div class="br-bottom m-b-3 p-t-2"></div>
                <div class="select-group-item">
                    <div class="control-group control-group--compact--large">
                        <label class="control control-radio block p-l-5">
                            <span class="i-container i-filled-theme p-r-5 p-l-4">
                                <img class="icon i-size-medium" v-bind:src="assetUrl('img/icons/bundle-v-lg-3.png')">
                            </span>
                            <span class="f-s-5">{{ trans('Release All Content Immediately') }}</span>
                            <input type="radio" value="skip_drip" v-model="item.pivot.content_release">
                            <div class="control-indicator control-radio-indicator"></div>
                        </label>
                    </div>
                </div>
                <div class="br-bottom m-b-3 p-t-2"></div>
                <div class="select-group-item">
                    <div class="control-group control-group--compact--large">
                        <label class="control control-radio block p-l-5">
                            <span class="i-container i-filled-theme p-r-5 p-l-4">
                                <img class="icon i-size-medium" v-bind:src="assetUrl('img/icons/bundle-v-lg-5.png')">
                            </span>
                            <span class="f-s-5">{{ trans('Release Date') }}</span>
                            <input type="radio" value="release_date" v-model="item.pivot.content_release">
                            <div class="control-indicator control-radio-indicator"></div>
                        </label>
                    </div>
                    <div v-if="item.pivot.content_release === 'release_date'" style="padding-left:128px;">
                        <span class="c-medium-tint f-s-2 m-b-2">{{ trans('Release the hub to all members on a set date') }}</span>
                        <div class="form-group m-b-3 small-4" v-bind:class="{'has-error': errors.has('date')}">
                            <date-pick
                                v-model="item.pivot.date"
                                v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick a date')}"
                                v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                                v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                                v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                                v-bind:weekdays="datePickLabels.weekdays"
                                v-bind:months="datePickLabels.months"
                            ></date-pick>
                        </div>
                        <div v-if="errors.has('date')" class="form-help m-t-5">{{ errors.get('date') }}</div>
                        <div class="control-group p-b-micro p-t-micro">
                            <label class="control control-checkbox block c-medium-tint m-b-0 p-r-0">
                                {{ trans('Release all content at the given date (overrides drip scheduling)') }}
                                <input type="checkbox" v-model="item.pivot.all_at_once">
                                <div class="control-indicator control-checkbox-indicator"></div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="br-bottom m-b-3 p-t-2"></div>
                <div class="select-group-item">
                    <div class="control-group control-group--compact--large">
                        <label class="control control-radio block p-l-5">
                            <span class="i-container i-filled-theme p-r-5 p-l-4">
                                <img class="icon i-size-medium" v-bind:src="assetUrl('img/icons/bundle-v-lg-6.png')">
                            </span>
                            <span class="f-s-5">{{ trans('Set Drip Schedule') }}</span>
                            <input type="radio" value="drip_schedule" v-model="item.pivot.content_release">
                            <div class="control-indicator control-radio-indicator"></div>
                        </label>
                    </div>
                    <div v-if="item.pivot.content_release === 'drip_schedule'" style="padding-left:128px;">
                        <span class="c-medium-tint f-s-2 m-b-2">{{ trans('Release the hub to all members after specific days') }}</span>
                        <div class="row row-collapse m-b-3">
                            <div class="column small-2">
                                <div class="form-group m-b-0">
                                    <input type="number" class="input__field" name="delay" v-model="item.pivot.delay" />
                                </div>
                            </div>
                            <div class="column small-4">
                                <div class="form-group m-b-0">
                                    <select class="input__field" v-model="item.pivot.interval">
                                        <option value="day">{{ trans('day(s)') }}</option>
                                        <option value="week">{{ trans('week(s)') }}</option>
                                        <option value="month">{{ trans('month(s)') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="control-group p-b-micro p-t-micro">
                            <label class="control control-checkbox block c-medium-tint m-b-0 p-r-0">
                                {{ trans('Release all content at the given date (overrides drip scheduling)') }}
                                <input type="checkbox" v-model="item.pivot.all_at_once">
                                <div class="control-indicator control-checkbox-indicator"></div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="br-bottom m-b-5 p-t-2"></div>
        </div>
        <h3 class="panel-title f-s-5">{{ trans('Hub Visibility') }}</h3>
        <div class="select-group">
            <div class="select-group-item">
                <div class="control-group control-group--compact--large">
                    <label class="control control-radio block p-l-5">
                        <span class="i-container i-filled-theme p-r-5 p-l-4">
                            <img class="icon i-size-medium" v-bind:src="assetUrl('img/icons/bundle-v-lg-3.png')">
                        </span>
                        <span class="f-s-5">{{ trans('Available to All Members') }}</span>
                        <input type="radio" value="all_members" v-model="item.pivot.visibility">
                        <div class="control-indicator control-radio-indicator"></div>
                    </label>
                </div>
            </div>
            <div class="br-bottom m-b-3 p-t-2"></div>
            <div class="select-group-item">
                <div class="control-group control-group--compact--large">
                    <label class="control control-radio block p-l-5">
                        <span class="i-container i-filled-theme p-r-5 p-l-4">
                            <img class="icon i-size-medium" v-bind:src="assetUrl('img/icons/bundle-v-lg-4.png')">
                        </span>
                        <span class="f-s-5">{{ trans('New Members Only') }}</span>
                        <input type="radio" value="new_members" v-model="item.pivot.visibility">
                        <div class="control-indicator control-radio-indicator"></div>
                    </label>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="updateAvailability">{{ trans('Update') }}</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '../modals/Modal.vue';
import { assetUrl } from '../../helpers.js';
import DatePick from 'vue-date-pick';
import DatePickLocalizationMixin from '../fields/DatePickLocalizationMixin.vue';
import Errors from '../../errors.js';

export default {
    components: {
        Modal,
        DatePick
    },
    mixins: [
        DatePickLocalizationMixin
    ],
    data() {
        return {
            item: {
                pivot: {
                    visibility: 'all_members',
                    content_release: 'skip_drip',
                    all_at_once: false,
                    date: null,
                    interval: 'day',
                    delay: 10,
                },
                id: null,
                enabled_hub_features: [],
            },
            index: null,
            show: false,
            originalItem: null,
            errors: new Errors(),
        }
    },
    watch: {
        show(value) {
            if (value) {
                // Save original availabilty settings so we can revert them if user just closes the modal
                this.originalItem = { ...this.item.pivot };
            }
        },
    },
    computed: {
        hasCourse() {
            if (this.item.id === null) {
                return false;
            }

            return this.item.enabled_hub_features.some(i => i.type === 'course');
        },
    },
    methods: {
        updateAvailability() {
            this.errors.clear();

            if (this.item.pivot.content_release === 'release_date' && ! this.item.pivot.date) {
                this.errors.record({date: [this.trans('Please select a date')]});

                return;
            }

            this.show = false;
            this.originalItem = null;

            this.$emit('close');
        },
        reset() {
            this.errors.clear();

            this.item = {
                pivot: {
                    visibility: 'all_members',
                    content_release: 'original_drip',
                    all_at_once: false,
                    date: null,
                    interval: 'day',
                    delay: 10,
                },
                id: null,
                enabled_hub_features: [],
            };
        },
        assetUrl(path) {
            return assetUrl(path);
        },
    },
    mounted() {
        this.$on('close', () => {
            if (this.originalItem) {
                // Revert original availability settings if user closed the modal without "Updating"
                this.item.pivot = this.originalItem;
            }
        });
    }
}
</script>