<template>
    <div>
        <div class="form-group form-group-compact">
            <label class="sc-reader">{{ trans('Time Period') }}</label>
            <select class="input-small input__field" v-model="selectedTimePeriod">
                <option value="">{{ trans('Time Period') }}</option>
                <option value="today">{{ trans('Today') }}</option>
                <option value="yesterday">{{ trans('Yesterday') }}</option>
                <option value="current_week">{{ trans('Current Week') }}</option>
                <option value="last_week">{{ trans('Last Week') }}</option>
                <option value="current_month">{{ trans('Current Month') }}</option>
                <option value="last_month">{{ trans('Last Month') }}</option>
                <option value="custom">{{ trans('Custom Period') }}</option>
            </select>
        </div>
        <div v-if="selectedTimePeriod === 'custom'">
            <div class="form-group m-b-0" v-bind:class="{'has-error': dateError}">
                <date-pick
                    v-model="dateFrom"
                    v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick a date')}"
                    v-on:input="validateDates"
                    v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                    v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                    v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                    v-bind:weekdays="datePickLabels.weekdays"
                    v-bind:months="datePickLabels.months"
                ></date-pick>
            </div>
            <div class="form-group" v-bind:class="{'has-error': dateError}">
                <date-pick
                    v-model="dateTo"
                    v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick a date')}"
                    v-on:input="validateDates"
                    v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                    v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                    v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                    v-bind:weekdays="datePickLabels.weekdays"
                    v-bind:months="datePickLabels.months"
                ></date-pick>
            </div>
            <div v-if="dateError" class="form-help">
                {{ trans('Invalid Date Range') }}
            </div>
        </div>
    </div>
</template>
<script>
import DatePick from 'vue-date-pick';
import DatePickLocalizationMixin from '../fields/DatePickLocalizationMixin.vue';

export default {
    components: {
        DatePick,
    },
    mixins: [
        DatePickLocalizationMixin,
    ],
    props: {
        timePeriod: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            selectedTimePeriod: this.timePeriod.period,
            dateTo: this.timePeriod.to,
            dateFrom: this.timePeriod.from,
            dateError: false,
        }
    },
    watch: {
        selectedTimePeriod() {
            this.emitChanges();
        },
        dateFrom() {
            this.emitChanges();
        },
        dateTo() {
            this.emitChanges();
        },
        timePeriod: {
            deep: true,
            handler(newValue) {
                this.selectedTimePeriod = newValue.period;
                this.dateFrom = newValue.from;
                this.dateTo = newValue.to;
            }
        },
    },
    methods: {
        /**
         * Validate date range and set "dateError" flag.
         *
         * @return {Void}
         */
         validateDates() {
            if (null === this.dateFrom || null === this.dateTo) {
                this.dateError = false;
            } else {
                let from = new Date(this.dateFrom);
                let to = new Date(this.dateTo);

                if (from > to) {
                    this.dateError = true;
                } else {
                    this.dateError = false;
                }
            }
        },
        /**
         * Emit changes to parent component.
         *
         * @return  {Void}
         */
        emitChanges() {
            if (this.dateError) {
                return;
            }

            this.$emit('update', {
                'period': this.selectedTimePeriod,
                'from': this.dateFrom,
                'to': this.dateTo,
            });
        },
    }
}
</script>