<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Select Membership') }}</h3>
        </div>
        <div class="br-bottom">
            <div class="row row-collapse">
                <div class="column"></div>
                <div class="form-group form-group-addon form-group-simple m-b-0 search-bar search-bar-expand fl-right text-right" v-bind:class="{'search-bar-open': searchVisible}">
                    <label class="m-t-1 p-t-2">
                        <input class="input__field" type="text" v-bind:placeholder="trans('Search')" v-model="query" ref="search-field">
                    </label>
                    <div class="form-append p-b-micro">
                        <button class="i-container i-filled-dark c-bright-bg btn btn-text width-auto-min" type="button" v-on:click="toggleSearch">
                            <!-- /icons/search.svg -->
                            <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.7 15.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S4 7.2 4 11.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S8.3 6 11.1 6s5.1 2.3 5.1 5.1-2.3 5-5.1 5z" class="icon"/></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="m-t-3">
            <div class="slat m-b-4 h-anim h-anim-select h-bg-accent--light" v-for="(membership, index) in filteredMemberships" v-bind:key="index">
                <a v-on:click.prevent="selectMembership(membership)" href="#select-membership" class="row row-middle row-compress-indent row-compress p-3">
                    <div class="slat-content column">
                        <h4 class="card-title c-primary f-s-4 m-b-0">{{ membership.name }}</h4>
                        <div class="card-type f-s-2 c-muted m-t-1">{{ membershipHubs(membership) }}</div>
                    </div>
                    <div class="slat-aside text-right m-r-4">
                        <div type="button" class="btn btn-text btn-text--accent h-anim-select-item">{{ trans('Select Membership') }}</div>
                    </div>
                </a>
            </div>
        </div>
        <div slot="footer"></div>
    </modal>
</template>
<script>
import HasMembershipHubs from '../../mixins/HasMembershipHubs';
import Modal from '../modals/Modal.vue';

export default {
    mixins: [
        HasMembershipHubs,
    ],
    components: {
        Modal
    },
    props: {
        memberships: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            show: false,
            query: '',
            searchVisible: false
        }
    },
    computed: {
        filteredMemberships() {
            let filteredMemberships = this.memberships;

            if (this.query !== null && this.query.length > 0) {
                filteredMemberships = filteredMemberships.filter(item => _.startsWith(item.name.toLowerCase(), this.query));
            }

            return filteredMemberships;
        },
    },
    methods: {
        toggleSearch() {
            this.searchVisible = ! this.searchVisible;

            if (this.searchVisible) {
                this.$refs['search-field'].focus();
            }
        },
        selectMembership(membership) {
            this.$emit('select-membership', membership);
            this.show = false;
        },
    }
}
</script>