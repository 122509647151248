<template>
    <div>
        <transition name="show">
            <div v-if="activeToolbar">
                <div class="btn--bar btn--bar--highlight inline rounded-ittybitty dropshadow-medium" style="position: absolute;margin-top: -22px;">
                    <a v-if=" ! allPublished" class="i-container btn btn-icon btn--bar--button btn--bar--highlight--button p-l-2 p-r-2 width-auto-min" href="#publish" v-on:click.prevent="changePublishStatus('published')">
                        <!-- /icons/s-checked.svg -->
                        <svg class="icon i-size-tiny" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#6F6FFF" class="icon" d="M7.5 11.5l-.056-.002a1.004 1.004 0 0 1-.726-.373l-2-2.5a1.002 1.002 0 0 1 1.563-1.25l1.302 1.628 5.59-5.591a.999.999 0 1 1 1.414 1.414l-6.38 6.381a.993.993 0 0 1-.707.293z"/><path fill="#6F6FFF" class="icon" d="M7.999 15.058a7.057 7.057 0 0 1-7.05-7.05c0-3.887 3.162-7.049 7.05-7.049 1.391 0 2.737.405 3.895 1.171a1 1 0 1 1-1.104 1.668 5.056 5.056 0 0 0-7.841 4.21c0 2.784 2.266 5.05 5.05 5.05s5.05-2.266 5.05-5.05a1 1 0 1 1 2 0 7.057 7.057 0 0 1-7.05 7.05z"/></svg>
                    </a>
                    <a v-else class="i-container btn btn-icon btn--bar--button btn--bar--highlight--button btn--bar--button--lighter p-l-2 p-r-2 width-auto-min" href="#unpublish" v-on:click.prevent="changePublishStatus('draft')">
                        <!-- /icons/s-checked.svg -->
                        <svg class="icon i-size-tiny" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#6F6FFF" class="icon" d="M7.5 11.5l-.056-.002a1.004 1.004 0 0 1-.726-.373l-2-2.5a1.002 1.002 0 0 1 1.563-1.25l1.302 1.628 5.59-5.591a.999.999 0 1 1 1.414 1.414l-6.38 6.381a.993.993 0 0 1-.707.293z"/><path fill="#6F6FFF" class="icon" d="M7.999 15.058a7.057 7.057 0 0 1-7.05-7.05c0-3.887 3.162-7.049 7.05-7.049 1.391 0 2.737.405 3.895 1.171a1 1 0 1 1-1.104 1.668 5.056 5.056 0 0 0-7.841 4.21c0 2.784 2.266 5.05 5.05 5.05s5.05-2.266 5.05-5.05a1 1 0 1 1 2 0 7.057 7.057 0 0 1-7.05 7.05z"/></svg>
                    </a>

                    <!--<a v-if=" ! allPreviewable" class="i-container btn btn-icon btn--bar--button btn--bar--highlight--button p-l-2 p-r-2 width-auto-min" href="#previewable" v-on:click.prevent="changePreviewStatus(1)">
                        <svg class="icon i-size-tiny" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M15.99 10.042c.002-.073-.012-.142-.025-.212-.009-.044-.002-.088-.017-.132C14.713 5.991 11.519 3.5 8 3.5S1.287 5.991.052 9.698a1 1 0 1 0 1.897.633C2.911 7.441 5.344 5.5 8 5.5c2.541 0 4.863 1.788 5.904 4.473a11.21 11.21 0 0 1-.661.94 1 1 0 1 0 1.598 1.203c.417-.555.807-1.081 1.049-1.648l.007-.015c.011-.024.024-.048.034-.072.018-.045.014-.092.024-.139.016-.066.033-.13.035-.2z"/><path d="M8 5.515c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zm0 5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5z"/></g></svg>
                    </a>
                    <a v-else class="i-container btn btn-icon btn--bar--button btn--bar--highlight--button btn--bar--button--lighter p-l-2 p-r-2 width-auto-min" href="#forbidden" v-on:click.prevent="changePreviewStatus(0)">
                        <svg class="icon i-size-tiny" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M15.99 10.042c.002-.073-.012-.142-.025-.212-.009-.044-.002-.088-.017-.132C14.713 5.991 11.519 3.5 8 3.5S1.287 5.991.052 9.698a1 1 0 1 0 1.897.633C2.911 7.441 5.344 5.5 8 5.5c2.541 0 4.863 1.788 5.904 4.473a11.21 11.21 0 0 1-.661.94 1 1 0 1 0 1.598 1.203c.417-.555.807-1.081 1.049-1.648l.007-.015c.011-.024.024-.048.034-.072.018-.045.014-.092.024-.139.016-.066.033-.13.035-.2z"/><path d="M8 5.515c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zm0 5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5z"/></g></svg>
                    </a>-->

                    <a class="i-container btn btn-icon btn--bar--button btn--bar--highlight--button p-l-2 p-r-2 width-auto-min" href="#clone" v-on:click.prevent="cloneSelected">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="i-size-tiny"><path d="M9 9V6.2c0-1.12 0-1.68.218-2.108.192-.377.497-.682.874-.874C10.52 3 11.08 3 12.2 3h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C21 4.52 21 5.08 21 6.2v5.6c0 1.12 0 1.68-.218 2.108a2.002 2.002 0 0 1-.874.874C19.48 15 18.92 15 17.803 15H15M9 9H6.2c-1.12 0-1.68 0-2.108.218a1.999 1.999 0 0 0-.874.874C3 10.52 3 11.08 3 12.2v5.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.427.218.987.218 2.105.218h5.607c1.117 0 1.676 0 2.104-.218.376-.192.683-.498.874-.874.218-.428.218-.987.218-2.105V15M9 9h2.8c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.427.218.987.218 2.105V15" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>

                    <a class="i-container btn btn-icon btn--bar--button btn--bar--highlight--button p-l-2 p-r-2 width-auto-min" href="#delete-all" v-on:click.prevent="deleteSelected">
                        <!-- /icons/s-trash.svg -->
                        <svg class="icon i-size-tiny" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                    </a>
                </div>
            </div>
        </transition>
        <div class="slat-group p-t-3 br p-b-2 m-b-5">
            <div class="slat-group-header row row-full row-middle">
                <div class="slat-controls m-vertical-none p-r-1 m-l-5">
                    <span class="m-r-2 i-container i-filled-dark">
                        <span class="handle">
                            <!-- /icons/drag.svg -->
                            <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                        </span>
                    </span>
                    <span class="control-group">
                        <div class="form-group-addon inline">
                            <label class="control control-checkbox block c-muted f-s-3 m-b-0 p-l-1">
                                <input class="checkbox-all" type="checkbox" v-on:change="selectAll" v-bind:checked="selected === item.id">
                                <div class="control-indicator control-checkbox-indicator"></div>
                            </label>
                        </div>
                    </span>
                </div>
                <div class="slat-content column">
                    <h2 class="f-w-bold f-s-4 m-b-0">
                        {{ item.name }}
                        <a class="i-container i-filled-dark" v-on:click.prevent="showModalEdit(item, index)" href="#edit-module">
                            <!-- /icons/edit.svg -->
                            <svg class="icon m-b-1 m-l-2 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.7 4.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM8.6 18H6v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L13.4 8 15 6.4 17.6 9 16 10.6z" class="icon"/></svg>
                        </a>
                    </h2>
                </div>
                <div class="slat-aside text-right m-4">
                    <a v-bind:href="addLessonUrl + '?module_id=' + item.encoded_id" class="btn btn-outline btn-outline--bright">{{ trans('Add lesson') }}</a>
                </div>
            </div>
            <div class="slat-group-content p-l-4 p-r-4 p-t-4 m-t-1 p-t-0">
                <lessons
                    v-bind:module-id="item.id"
                    v-bind:lessons="item.lessons || []"
                    v-bind:checked.sync="checked"
                    v-on:update:checked="updateSelected"
                    ref="lessons"
                ></lessons>
            </div>
        </div>
    </div>
</template>
<script>
import { moduleCloneLink } from '../routes';

    export default {
        props: {
            item: {
                type: Object
            },
            addLessonUrl: {
                type: String
            },
            selected: {
                type: Number
            },
            index: {
                type: Number
            }
        },
        data() {
            return {
                checked: []
            }
        },
        computed: {
            activeToolbar() {
                return this.checked.length > 0;
            },
            allPublished() {
                return this.item.lessons
                    .filter(lesson => _.indexOf(this.checked, lesson.id) >= 0)
                    .filter(lesson => lesson.status === 'draft').length === 0;
            },
            allPreviewable() {
                return this.item.lessons
                    .filter(lesson => _.indexOf(this.checked, lesson.id) >= 0)
                    .filter(lesson => lesson.preview_enabled == 0).length === 0;
            }
        },
        watch: {
            selected(value) {
                if (this.selected !== null && value !== this.item.id) {
                    this.checked = [];
                }
            }
        },
        methods: {
            updateSelected() {
                if (this.checked.length !== this.item.lessons.length) {
                    this.$emit('update:selected', null);
                } else {
                    this.$emit('update:selected', this.item.id);
                }
            },
            showModalEdit(item, index) {
                // Forward the event to the parent
                this.$emit('show-modal-edit', item, index);
            },
            selectAll() {
                if (this.selected !== this.item.id) {
                    this.checked = this.item.lessons.map(item => item.id);
                    this.$emit('update:selected', this.item.id);
                } else {
                    this.checked = [];
                    this.$emit('update:selected', null);
                }
            },
            deselectAll() {
                this.checked = [];
                this.$emit('update:selected', null);
            },
            changePublishStatus(status) {
                let lessonsComponent = this.$refs['lessons'];

                this.item.lessons.filter((lesson) => {
                    return _.indexOf(this.checked, lesson.id) >= 0 && lesson.status !== status;
                }).forEach((lesson) => {
                    lessonsComponent.changePublishStatus(lesson, status);
                });
            },
            changePreviewStatus(enabled) {
                let lessonsComponent = this.$refs['lessons'];

                this.item.lessons.filter((lesson) => {
                    return _.indexOf(this.checked, lesson.id) >= 0 && lesson.preview_enabled !== enabled;
                }).forEach((lesson) => {
                    lessonsComponent.changePreviewStatus(lesson, enabled);
                });
            },
            deleteSelected() {
                this.$dialog.confirm(this.trans('Are you sure you want to delete selected lessons?'), {loader: true})
                    .then((dialog) => {
                        let lessonsComponent = this.$refs['lessons'];
                        let lessonsCount = 0;

                        this.item.lessons.filter((lesson) => {
                            return _.indexOf(this.checked, lesson.id) >= 0;
                        }).forEach((lesson) => {
                            lessonsCount += 1;
                            lessonsComponent.deleteLesson(lesson);
                        });

                        // Dummy timeout because we currently don't return promises when deleting lessons
                        setTimeout(() => {
                            dialog.close();
                        }, 500 * lessonsCount);
                    });
            },
            cloneSelected() {
                // Check if the whole module is selected to be cloned
                if (this.selected) {
                    this.$dialog.confirm(this.trans('Are you sure you want to clone selected module and all of its lessons?'), {loader: true})
                        .then((dialog) => {
                            window.axios
                                .post(moduleCloneLink(this.item))
                                .then((response) => {
                                    dialog.close();
                                    this.deselectAll();
                                    this.$emit('module:cloned', response.data, this.index + 1);
                                })
                                .catch((error) => {
                                    dialog.close();
                                    this.deselectAll();
                                    this.$toast.danger(error.response.data.message);
                                });
                        });

                    return;
                }

                // Clone individual lessons
                this.$dialog.confirm(this.trans('Are you sure you want to clone selected lessons?'), {loader: true})
                    .then((dialog) => {
                        const lessonsComponent = this.$refs['lessons'];

                        lessonsComponent.cloneLessons(
                            this.item.lessons
                                .filter((lesson) => this.checked.indexOf(lesson.id) >= 0)
                                .map((lesson) => lesson.encoded_id)
                        )
                        .then(() => {
                            dialog.close();
                            this.deselectAll();
                        })
                        .catch((error) => {
                            dialog.close();
                            this.deselectAll();
                            this.$toast.danger(error.response.data.message);
                        });
                    });
            }
        }
    }
</script>