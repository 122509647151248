export default {
    methods: {
        membershipHubs(membership) {
            if (membership.hubs.length === 0) {
                return this.trans('No hubs selected!');
            }

            return membership.hubs.reduce((hubsList, hub) => {
                if (hubsList.length > 0) {
                    hubsList += ', ';
                }

                hubsList += hub.title;

                return hubsList;
            }, '');
        }
    }
}