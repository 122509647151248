<template>
    <div class="slat m-b-4">
        <div class="row row-compress-indent row-compress row-top row-integration-options">
            <div class="slat-figure--medium column small-12 cover-container">
                <img class="br br-c--light integration-icon" v-bind:src="activeIntegration.icon">

                <div v-if="$parent.isLoading" class="cover-overlay cover-overlay--bright">
                    <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny integration-preloader">
                        <svg class="i-stroke-bright" width="24" height="24"><g><circle cx="50%" cy="50%" r="10" stroke-width="2" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                    </div>
                </div>
            </div>

            <div class="slat-content column">
                <h2 class="card-title c-primary f-s-4 m-b-1">{{ activeIntegration.title }}</h2>
                <p class="f-s-3 c-muted m-b-0">{{ integrationLabel }}</p>

                <div v-if="hasLists" class="form-group m-t-2" v-bind:class="{'has-error': errors.has(fieldNamePrefix + '.list')}">
                    <label>{{ activeIntegration.config.list_label }}</label>
                    <select v-bind:name="fieldNamePrefix + '[list]'" v-model="list" v-bind:disabled="$parent.isLoading == true" class="input__field">
                        <option value="">---</option>
                        <option v-for="list in activeLists" v-bind:value="list.id" v-bind:key="list.id">{{ list.name }}</option>
                    </select>
                </div>
                <div v-if="errors.has(fieldNamePrefix + '.list')" class="form-help">{{ errors.get(fieldNamePrefix + '.list') }}</div>

                <integration-tags
                    v-if="hasTags"
                    v-bind:integration="activeIntegration"
                    v-bind:selected-tags="membershipTags"
                    v-bind:available-tags="activeTags"
                    v-bind:errors="errors"
                    v-bind:field-name-prefix="fieldNamePrefix"
                    v-on:change="updateMembershipTags"
                ></integration-tags>

                <integration-extra-fields
                    v-if="hasExtraFields && showExtraFields"
                    v-bind:integration="activeIntegration"
                    v-bind:selected-extra-fields="membershipIntegration.data.extraFields"
                    v-bind:available-extra-fields="availableExtraFields"
                    v-bind:errors="errors"
                    v-bind:field-name-prefix="fieldNamePrefix"
                ></integration-extra-fields>
            </div>
            <div class="slat-aside text-right m-r-4 m-t-2">
                <a class="btn btn-secondary" data-toggle="serviceClose" v-on:click="removeActiveIntegration">{{ trans('Remove :integration', {'integration': activeIntegration.title}) }}</a>
            </div>
        </div>
        <input type="hidden" v-bind:name="fieldNamePrefix + '[integration]'" v-bind:value="integration.uid" />
    </div>
</template>
<script>
import { EventBus } from '../../../event-bus.js';
import IntegrationTags from './IntegrationTags.vue';
import IntegrationExtraFields from './IntegrationExtraFields.vue';
import { membershipExtraFieldsUrl } from '../../../routes.js';

export default {
    components: {
        IntegrationTags,
        IntegrationExtraFields,
    },
    props: {
        activeIntegration: {
            type: Object
        },
        membership: {
            type: Object
        },
        activeLists: {
            type: Array
        },
        activeTags: {
            type: [Array, Object]
        },
        errors: {
            type: [Array, Object]
        },
        activeExtraFields: {
            type: [Array, Object]
        },
        membershipIntegration: {
            type: Object
        },
        fieldNamePrefix: {
            type: String
        },
        showExtraFields: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            list: this.membershipIntegration.listId,
            membershipTags: this.membershipIntegration.tags,
            integration: this.activeIntegration,
            availableExtraFields: this.activeExtraFields,
            integrationLabel: this.fieldNamePrefix === 'purchase'
                ? this.trans('Send emails to :integration', {'integration': this.activeIntegration.title})
                : this.trans('Send leads to :integration', {'integration': this.activeIntegration.title})
        };
    },
    computed: {
        hasTags() {
            return this.$parent.hasTags;
        },
        hasLists() {
            return this.activeIntegration.provider !== 'infusionsoft'
                && this.activeIntegration.provider !== 'keap'
        },
        hasExtraFields() {
            return this.$parent.hasExtraFields;
        },
    },
    watch: {
        list(list) {
            EventBus.$emit('form:element:changed', this.fieldNamePrefix + '[list]', list);

            if (this.showExtraFields) {
                this.updateExtraFields(list);
            }
        },
    },
    methods: {
        updateMembershipTags(tags) {
            this.membershipTags = tags;
        },
        removeActiveIntegration() {
            this.$emit('remove:active');
        },
        updateExtraFields(list) {
            this.availableExtraFields = [];
            this.$parent.loading(true);

            window.axios.get(membershipExtraFieldsUrl(this.activeIntegration.uid, list))
                .then((response) => {
                    this.$parent.loading(false);
                    this.availableExtraFields = response.data;
                });
        },
    },
    created() {
        EventBus.$emit('form:element:changed', this.fieldNamePrefix + '[integration_uid]', this.integration.uid);
    }
}
</script>

<style>
    .row-integration-options {
        position: relative;
    }

    .integration-preloader-container {
        position: absolute;
        top: 0;
        left: 1.5em;
        background: rgba(255,255,255,.85);
        width: 74px;
        height: 74px;
    }

    .integration-preloader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -12px;
        margin-top: -12px;
    }
</style>